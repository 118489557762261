<template>
    <div class="tab-pane fade" id="offers" role="tabpanel">
        <div class="card mt-5 mb-xl-10">
            <div class="card-header">
                <h3 class="card-title">Offers</h3>
            </div>
            <div class="card-body">
                <div class="accordion" id="kt_accordion">
                    <div class="accordion-item"
                         v-for="(item, index) in invoice.InvoiceDiscountOffers"
                         :key="index">
                        <h2 class="accordion-header" :id="'kt_accordion_'+index+'_header_'+index">
                            <button class="accordion-button  fw-bold collapsed" type="button" aria-expanded="false"
                                    data-bs-toggle="collapse"
                                    :data-bs-target="'#kt_accordion_'+index+'_body_'+index"
                                    :aria-controls="'kt_accordion_'+index+'_body_'+index">
                                <span class="me-5" v-html="getDiscountOfferStatusLabel(item.Status)"></span>
                                <span class="fs-5 mx-5">Offer created date: <span class="fs-5 badge badge-light-primary">{{dateTimeFormat(item.Created)}}</span></span>
                            </button>
                        </h2>
                        <div :id="'kt_accordion_'+index+'_body_'+index"
                             class="accordion-collapse collapse "
                             :aria-labelledby="'kt_accordion_'+index+'_header_'+index"
                             :data-bs-parent="'#kt_accordion'">
                            <div class="accordion-body">
                                <div v-if="item.Status === DiscountOfferStatus.PENDING_APPROVAL" class="row mb-4">
                                    <div class="col-12">
                                        <button hidden @click="rejectOffer(item.Id)" class="btn btn-danger float-end me-4">
                                            Reject All Options
                                        </button>
                                    </div>
                                </div>

                                <div  v-for="(option, optIndex) in item.InvoiceDiscountOfferOptions" :key="optIndex">
                                    <div class="row">
                                        <div class="col-3">
                                            <span class="text-gray-600">Payment Date - </span> <span class="fw-bold mt-5"> {{ countSubmitedDate(option.PaymentPeriodInDays,item) }}</span>
                                        </div>
                                        <div class="col-3">
                                            <span class="text-gray-600">Discount Percent - </span> <span class="fw-bold mt-5"> {{ option.DiscountPercent.toTwoFractionDigits() }} %</span>
                                        </div>
                                        <div class="col-3">
                                            <span class="text-gray-600">New Total Amount - </span> <span class="fw-bold mt-5"> {{countNewTotalAmount(option.DiscountPercent) }} </span>
                                        </div>
                                        <div class="col-2">
                                            <span class="fw-bold mt-5 badge-light-success fs-4" v-if="option.IsAccepted">Accepted</span>
                                        </div>
                                        <div v-if="item.Status === DiscountOfferStatus.PENDING_APPROVAL" class="col-1">
                                            <div v-if="canAcceptDate(option.PaymentPeriodInDays)">
                                                <button @click="acceptOfferOption(item.Id, option.Id)" class="btn btn-primary me-4 mt-0 float-end">
                                                    Accept
                                                </button>
                                            </div>
                                            <div v-else>
                                                <div class="text-light-danger p-3  bg-danger me-4 float-end" style="border-radius:5px;">
                                                    Expired
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="invoice?.InvoiceDiscountOffers?.length === 0">
                    <div class="alert alert-danger p-5 text-center">
                        <h4 class="mb-1 text-dark">No Data</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, ref } from "vue";
    import { DiscountOfferStatus, InvoiceVM, InvoiceDiscountOfferVM } from "../../../clients/ApiClient";
    import moment from "moment";
    import Swal from "sweetalert2/dist/sweetalert2.js";
    import { useVendorStore } from "@/store/vendor/VendorStore";
    import { getDiscountOfferStatusLabel } from "@/extensions/statusExtension";
    import '../../extensions/numberExtension';
    import { dateTimeFormat } from "@/extensions/dateExtension";
    export default defineComponent({
        name: "vendor-invoice-offers",
        components: {},

        setup() {
            const vendorStore = useVendorStore();
            const invoice = computed((): InvoiceVM => vendorStore.invoiceWithOnlyApprovalOffersState);

            const swalOptions = {
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true
            };

            const successSwal = function () {
                Swal.fire({
                    icon: 'success',
                    title: "Done",
                    showConfirmButton: false,
                    timer: 1500
                });
            }

            async function rejectOffer(offerId: string) {
                Swal.fire(swalOptions).then(async function (result) {
                    if (result.value) {
                        await vendorStore.rejectOffer({ RejectedOffer: offerId, InvoiceId: invoice.value.Id });
                    } else if (result.dismiss === "cancel") {
                        Swal.fire("Cancelled",);
                    }
                });
            }

            async function acceptOfferOption(offerId: string, optionId: string) {
                Swal.fire(swalOptions).then(async function (result) {
                    if (result.value) {
                        await vendorStore.acceptOfferOption({ InvoiceId: invoice.value.Id, AcceptedOption: optionId, AcceptedOffer: offerId });
                    } else if (result.dismiss === "cancel") {
                        Swal.fire(
                            "Cancelled",
                        );
                    }
                });
            }



            function canAcceptDate(days: number) {
                let offerBeforeDueDate = (moment(invoice.value.InvoiceDiscountOffers?.find(i => i.Status == DiscountOfferStatus.PENDING_APPROVAL)?.SubmittedToVendorDate).add(days, 'd').isBefore(moment(moment(invoice.value.DueDate))));
                let offerAfterNow = (moment(invoice.value.InvoiceDiscountOffers?.find(i => i.Status == DiscountOfferStatus.PENDING_APPROVAL)?.SubmittedToVendorDate).add(days, 'd').isAfter(moment(Date.now())));

                return offerBeforeDueDate && offerAfterNow;
            }
            function countSubmitedDate(days: number, offer: InvoiceDiscountOfferVM) {
                if (offer.Status == DiscountOfferStatus.DRAFT) {
                    return moment(moment(Date.now()).add(days, 'd').format('MM-DD-YYYY')).format('MM-DD-YYYY');
                }
                else {
                    return moment((moment(offer?.SubmittedToVendorDate)).add(days, 'd').format('MM-DD-YYYY')).format('MM-DD-YYYY');

                }
            }

            function countNewTotalAmount(discountPercent: number) {
                if (invoice.value.TotalAmount) {
                    return (invoice.value.TotalAmount - (invoice.value.TotalAmount * discountPercent) / 100).toFixed(1)
                }
                return "N/A";
            }
            return {
                moment,
                acceptOfferOption,
                rejectOffer,
                countNewTotalAmount,
                getDiscountOfferStatusLabel,
                invoice,
                canAcceptDate,
                dateTimeFormat,
                DiscountOfferStatus,
                countSubmitedDate
            };
        },
    });
</script>