/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptedDiscountOfferCommand } from '../models/AcceptedDiscountOfferCommand';
import type { AddDiscountOfferCommand } from '../models/AddDiscountOfferCommand';
import type { AddDiscountOfferOptionsCommand } from '../models/AddDiscountOfferOptionsCommand';
import type { CancelDiscountOffersCommand } from '../models/CancelDiscountOffersCommand';
import type { ChangeInvoiceDiscountOfferVM } from '../models/ChangeInvoiceDiscountOfferVM';
import type { CompanyDashboardDataModel } from '../models/CompanyDashboardDataModel';
import type { DeleteDiscountOfferCommand } from '../models/DeleteDiscountOfferCommand';
import type { DeleteDiscountOfferOptionsByOptionIdsCommand } from '../models/DeleteDiscountOfferOptionsByOptionIdsCommand';
import type { GridOperationsModel } from '../models/GridOperationsModel';
import type { InvoiceDiscountOfferVM } from '../models/InvoiceDiscountOfferVM';
import type { InvoicesVM } from '../models/InvoicesVM';
import type { InvoiceVM } from '../models/InvoiceVM';
import type { RejectedDiscountOfferCommand } from '../models/RejectedDiscountOfferCommand';
import type { SendDiscountOffersCommand } from '../models/SendDiscountOffersCommand';
import type { UpdateDiscountOfferCommand } from '../models/UpdateDiscountOfferCommand';
import type { VendorDashboardDataModel } from '../models/VendorDashboardDataModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class InvoicesService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns InvoiceVM Success
     * @throws ApiError
     */
    public get(
        id: string,
    ): CancelablePromise<InvoiceVM> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns InvoiceVM Success
     * @throws ApiError
     */
    public getFromInternal(
        id: string,
    ): CancelablePromise<InvoiceVM> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/GetFromInternal/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns InvoiceVM Success
     * @throws ApiError
     */
    public getInvoices(): CancelablePromise<Array<InvoiceVM>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/All',
        });
    }

    /**
     * @param companyId
     * @returns InvoiceVM Success
     * @throws ApiError
     */
    public getInvoicesByCompanyId(
        companyId: string,
    ): CancelablePromise<Array<InvoiceVM>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/All/ByCompany/{companyId}',
            path: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @param companyId
     * @returns CompanyDashboardDataModel Success
     * @throws ApiError
     */
    public getCompanyDashboardData(
        companyId?: string,
    ): CancelablePromise<CompanyDashboardDataModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/GetCompanyDashboardData',
            query: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @returns VendorDashboardDataModel Success
     * @throws ApiError
     */
    public getVendorDashboardData(): CancelablePromise<VendorDashboardDataModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/VendorDashboardData',
        });
    }

    /**
     * @param requestBody
     * @returns InvoicesVM Success
     * @throws ApiError
     */
    public getInvoicesByVendorId(
        requestBody?: GridOperationsModel,
    ): CancelablePromise<InvoicesVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/All/ByVendor',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param vendorId
     * @returns InvoicesVM Success
     * @throws ApiError
     */
    public getAllInvoicesByVendorId(
        vendorId?: string,
    ): CancelablePromise<InvoicesVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/AllInvoices/ByVendor',
            query: {
                'vendorId': vendorId,
            },
        });
    }

    /**
     * @param vendorId
     * @param issueDateYear
     * @returns InvoicesVM Success
     * @throws ApiError
     */
    public getInvoicesByVendorAndYear(
        vendorId: string,
        issueDateYear: number,
    ): CancelablePromise<InvoicesVM> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/All/ByVendorAndYear/{vendorId}/{issueDateYear}',
            path: {
                'vendorId': vendorId,
                'issueDateYear': issueDateYear,
            },
        });
    }

    /**
     * @param requestBody
     * @returns InvoiceDiscountOfferVM Success
     * @throws ApiError
     */
    public addDiscountOffer(
        requestBody?: AddDiscountOfferCommand,
    ): CancelablePromise<InvoiceDiscountOfferVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOffers',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns InvoiceDiscountOfferVM Success
     * @throws ApiError
     */
    public addDiscountOfferFromInternal(
        requestBody?: AddDiscountOfferCommand,
    ): CancelablePromise<InvoiceDiscountOfferVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/AddDiscountOfferFromInternal',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param invoiceId
     * @returns InvoiceDiscountOfferVM Success
     * @throws ApiError
     */
    public getDiscountOffers(
        invoiceId: string,
    ): CancelablePromise<Array<InvoiceDiscountOfferVM>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Invoices/DiscountOffers/{invoiceId}',
            path: {
                'invoiceId': invoiceId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteDiscountOffer(
        requestBody?: DeleteDiscountOfferCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOffers/Delete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public updateDiscountOffer(
        requestBody?: UpdateDiscountOfferCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/Api/V1/Invoices/DiscountOffers/Update',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns ChangeInvoiceDiscountOfferVM Success
     * @throws ApiError
     */
    public acceptOffer(
        requestBody?: AcceptedDiscountOfferCommand,
    ): CancelablePromise<ChangeInvoiceDiscountOfferVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOffers/Accept',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public cancelDiscountOffers(
        requestBody?: CancelDiscountOffersCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOffers/Cancel',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public rejectDiscountOffers(
        requestBody?: RejectedDiscountOfferCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOffers/Reject',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public sendDiscountOffers(
        requestBody?: SendDiscountOffersCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOffers/Send',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public sendDefaultDiscountOffers(
        requestBody?: SendDiscountOffersCommand,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOffers/SendDefaultOffer',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public addDiscountOfferOptions(
        requestBody?: AddDiscountOfferOptionsCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOfferOptions',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteDiscountOfferOptionsByOptionId(
        requestBody?: DeleteDiscountOfferOptionsByOptionIdsCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/DiscountOfferOptions/DeleteByOptionsIds',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public getAllInvoices(
        requestBody?: GridOperationsModel,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Invoices/GetAllInvoices',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
