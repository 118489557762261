/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { WebHookDataDto } from '../models/WebHookDataDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WebhooksService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public updateDataSet(
        requestBody?: WebHookDataDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Accounting/Webhooks/UpdateDataSet',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
