import { DiscountOfferStatus } from "../../clients/ApiClient";

export const getDiscountOfferStatusLabel = (value: DiscountOfferStatus) => {

    switch (value) {
        case DiscountOfferStatus.DRAFT:
            return `<span class="fs-5 badge badge-light-success ">${value}</span>`;

        case DiscountOfferStatus.PENDING_APPROVAL:
            return `<span class="fs-5 badge badge-light-warning">Pending Approval <i class="bi bi-send   badge-light-warning mx-1 "></span>`;

        case DiscountOfferStatus.REJECTED:
            return `<span class="fs-5 badge badge-light-danger">${value} <i class="bi bi-x-lg  badge-light-danger mx-1 "></span>`;

        case DiscountOfferStatus.ACCEPTED:
            return `<span class="fs-5 badge badge-light-success">${value}<i class="bi bi-check-lg   badge-light-success mx-1 "></span>`;

        case DiscountOfferStatus.CANCELLED:
            return `<span class="fs-5 badge badge-light-danger">${value} <i class="bi bi-x-lg   badge-light-danger mx-1 "></span>`;
        case DiscountOfferStatus.EXPIRED:
            return `<span class="fs-5 badge badge-light-danger">${value} <i class="bi bi-clock   badge-light-danger mx-1 "></i></span>`;
        default:
            break;
    }
};