/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateQpRuleCommand } from '../models/CreateQpRuleCommand';
import type { DeleteQpRuleCommand } from '../models/DeleteQpRuleCommand';
import type { GridOperationsModel } from '../models/GridOperationsModel';
import type { InsertQpRulesFromTemplateCommand } from '../models/InsertQpRulesFromTemplateCommand';
import type { QpRuleDto } from '../models/QpRuleDto';
import type { UpdateQpRuleCommand } from '../models/UpdateQpRuleCommand';
import type { UpdateVendorCommand } from '../models/UpdateVendorCommand';
import type { VendorDto } from '../models/VendorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class VendorsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param id
     * @returns VendorDto Success
     * @throws ApiError
     */
    public getVendor(
        id: string,
    ): CancelablePromise<VendorDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Vendors/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns VendorDto Success
     * @throws ApiError
     */
    public getVendorFromInternal(
        id: string,
    ): CancelablePromise<VendorDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Vendors/GetVendorFromInternal/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns VendorDto Success
     * @throws ApiError
     */
    public updateVendor(
        requestBody?: UpdateVendorCommand,
    ): CancelablePromise<VendorDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/Api/V1/Vendors',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns QpRuleDto Success
     * @throws ApiError
     */
    public createQpRule(
        requestBody?: CreateQpRuleCommand,
    ): CancelablePromise<QpRuleDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Vendors/QpRules',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns QpRuleDto Success
     * @throws ApiError
     */
    public updateQpRule(
        requestBody?: UpdateQpRuleCommand,
    ): CancelablePromise<QpRuleDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/Api/V1/Vendors/QpRules',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns QpRuleDto Success
     * @throws ApiError
     */
    public insertFromTemplate(
        requestBody?: InsertQpRulesFromTemplateCommand,
    ): CancelablePromise<Array<QpRuleDto>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Vendors/QpRules/InsertFromTemplate',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteQpRule(
        requestBody?: DeleteQpRuleCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Vendors/QpRules/Delete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns VendorDto Success
     * @throws ApiError
     */
    public getAllVendors(
        requestBody?: GridOperationsModel,
    ): CancelablePromise<Array<VendorDto>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Vendors/All',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param companyId
     * @returns VendorDto Success
     * @throws ApiError
     */
    public getVendorsByCompany(
        companyId: string,
    ): CancelablePromise<Array<VendorDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Vendors/Company/{companyId}',
            path: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @param vendorId
     * @returns any Success
     * @throws ApiError
     */
    public sendCredentials(
        vendorId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Vendors/SendCredentials/{vendorId}',
            path: {
                'vendorId': vendorId,
            },
        });
    }

    /**
     * @param email
     * @returns VendorDto Success
     * @throws ApiError
     */
    public getVendorByEmail(
        email?: string,
    ): CancelablePromise<VendorDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Vendors/GetVendorByEmail',
            query: {
                'email': email,
            },
        });
    }

}
