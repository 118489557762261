/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CheckIfCompanyExistsCommand } from '../models/CheckIfCompanyExistsCommand';
import type { CheckIfCompanyWithNameExistsCommand } from '../models/CheckIfCompanyWithNameExistsCommand';
import type { CheckIfUserIsInCompanyCommand } from '../models/CheckIfUserIsInCompanyCommand';
import type { CompanyAccountingStatusDetails } from '../models/CompanyAccountingStatusDetails';
import type { CompanyRegistrationDto } from '../models/CompanyRegistrationDto';
import type { CompanyVM } from '../models/CompanyVM';
import type { CreateCompanyCommand } from '../models/CreateCompanyCommand';
import type { DeleteCompanyLogoCommand } from '../models/DeleteCompanyLogoCommand';
import type { RemoveUserFromCompanyCommand } from '../models/RemoveUserFromCompanyCommand';
import type { UserVm } from '../models/UserVm';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CompanyService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns CompanyVM Success
     * @throws ApiError
     */
    public createCompany(
        requestBody?: CreateCompanyCommand,
    ): CancelablePromise<CompanyVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id
     * @returns CompanyVM Success
     * @throws ApiError
     */
    public getCompany(
        id: string,
    ): CancelablePromise<CompanyVM> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Company/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns UserVm Success
     * @throws ApiError
     */
    public checkIfUserIsInCompany(
        requestBody?: CheckIfUserIsInCompanyCommand,
    ): CancelablePromise<UserVm> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company/User/Checking',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns CompanyVM Success
     * @throws ApiError
     */
    public checkIfCompanyExists(
        requestBody?: CheckIfCompanyExistsCommand,
    ): CancelablePromise<CompanyVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company/Checking',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public checkIfCompanyWithNameExists(
        requestBody?: CheckIfCompanyWithNameExistsCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company/CheckingByName',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteUserFromCompany(
        requestBody?: RemoveUserFromCompanyCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company/User/Delete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param formData
     * @returns any Success
     * @throws ApiError
     */
    public uploadLogo(
        formData?: {
            logo?: Blob;
            CompanyId?: string;
        },
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company/UploadLogo',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id
     * @returns any Success
     * @throws ApiError
     */
    public downloadLogo(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Company/DownloadLogo/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public deleteCompanyLogo(
        requestBody?: DeleteCompanyLogoCommand,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company/DeleteCompanyLogo',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createCompany1(
        requestBody?: CompanyRegistrationDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Company/CreateCompany',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns CompanyAccountingStatusDetails Success
     * @throws ApiError
     */
    public getStatus(): CancelablePromise<CompanyAccountingStatusDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Company/GetAccountingConnectionStatus',
        });
    }

}
