import { defineStore } from "pinia";
import {
	AddDiscountOfferCommand,
	ApiClient, ApiError, CancelablePromise, CancelDiscountOffersCommand, CompanyDashboardDataModel,
	DiscountOfferStatus, InvoiceStatus, InvoiceStatusEventDto, InvoicesVM,
	InvoiceVM, SendDiscountOffersCommand, UpdateDiscountOfferCommand
} from "../../../clients/ApiClient";
import { PropType } from "vue";
import { api } from "@/clients/ApiClientService";
import router from "@/router";
import { errorToast, successToast } from "@/helpers/customToast";
// import {} from "@/extensions/cancelablePromiseExtension";


export const useInvoiceStore = defineStore('invoiceStore', {
	state: () => {
		return {
			invoiceState: Object as Partial<InvoiceVM>,
			companyDashboardState: Object as Partial<CompanyDashboardDataModel>,
			invoiceFilteredByVendorAndYearState: Object as Partial<InvoicesVM>,
			canAddOffer: false,
			canEditOffer: false,
			isEditOffer: false,
			isAddOfferFromIndex: false,
			editOfferId: '',
		}
	},
	actions: {
		async getInvoice<InvoiceVM>(invoiceId) {
			const res = await api.invoices.get(invoiceId).resolve();
			if (res.result !== undefined) {
				this.$state.invoiceState = res.result
			}
			const offers = this.$state.invoiceState.InvoiceDiscountOffers;
			if (offers) {
				this.$state.canAddOffer = offers.every(e => {
					return (e.Status === DiscountOfferStatus.CANCELLED || e.Status === DiscountOfferStatus.REJECTED || e.Status === DiscountOfferStatus.EXPIRED);
				});
				this.$state.canAddOffer = this.$state.canAddOffer && (this.$state.invoiceState.Status !== InvoiceStatus.PAID);
				this.$state.canAddOffer = this.$state.canAddOffer && (Date.parse(this.$state.invoiceState.DueDate ?? '01 Jan 1970 00:00:00 GMT') > Date.now());
			}
		},

		async getInvoiceByVendorAndYear(vendorId: string, year: number) {
			const res = await api.invoices.getInvoicesByVendorAndYear(vendorId, year).resolve();
			if (res.result !== undefined) {
				this.$state.invoiceFilteredByVendorAndYearState = res.result;
			}
		},

		async addInvoiceOffer<bool>(model: AddDiscountOfferCommand) {
			const res = await api.invoices.addDiscountOffer(model).resolve();
			if (res.succeed) {
				successToast()
				await this.getInvoice(model.InvoiceId);
			} else {
				errorToast();
			}
			return res.succeed
		},


		async addInvoiceOfferAndSend<bool>(model: AddDiscountOfferCommand) {
			let res = false;
			const addedRes = await api.invoices.addDiscountOffer(model).resolve();
			if (addedRes.succeed) {
				const sentRes = await api.invoices.sendDiscountOffers({
					SentOfferId: addedRes.result?.Id,
					InvoiceId: model.InvoiceId,
				}).resolve();
				res = addedRes.succeed && sentRes.succeed;
			}
			if (res) {
				await this.getInvoice(model.InvoiceId);
			}
			return res;
		},


		async updateInvoiceOffer<bool>(model: UpdateDiscountOfferCommand) {
			const res = await api.invoices.updateDiscountOffer(model).resolve();
			if (res.succeed) {
				await this.getInvoice(model.InvoiceId);
				successToast()
			} else {
				errorToast();
			}
			return res.succeed
		},

		async cancelInvoiceOffer<bool>(model: CancelDiscountOffersCommand) {
			const res = await api.invoices.cancelDiscountOffers(model).resolve();
			if (res.succeed) {
				await this.getInvoice(model.InvoiceId);
				successToast()
			} else {
				errorToast();
			}
			return res.succeed;
		},


		async sendOfferForApproval<bool>(model: SendDiscountOffersCommand) {
			const res = await api.invoices.sendDiscountOffers(model).resolve();
			if (res.succeed) {
				await this.getInvoice(this.$state.invoiceState.Id);
			}
			// else{
			//     errorToast();
			// }
			return res.succeed
		},


		async getCompanyDashboardData<CompanyDashboardDataModel>() {
			const res = await api.invoices.getCompanyDashboardData(localStorage.getItem('company_ID')!).resolve();
			if (res.succeed && res.result !== undefined) {
				this.$state.companyDashboardState = res.result
			}
			return res.succeed
		},

		setUpdateOfferData(offerId: string) {
			this.$state.isEditOffer = true;
			this.$state.isAddOfferFromIndex = true;
			this.$state.editOfferId = offerId;
		},
		setDataFromIndex() {
			this.$state.isAddOfferFromIndex = true;
		},
		restoreUpdateOfferData() {
			this.$state.isEditOffer = false;
			this.$state.isAddOfferFromIndex = false;
			this.$state.editOfferId = '';
		},
	},

	getters: {
		invoice: (state) => state,
	}
});