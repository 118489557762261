import moment from "moment";

export const dateFormat = (value: string) => {
    return moment(value).format('MM-DD-YYYY')
};

export const dateTimeFormat = (value: string) => {
    return moment(value).format('MM-DD-YYYY HH:mm')
};

//export const expirationDateStatus = (SubmittedToVendorDate: string, OfferExpirationDays: number) => {
//    const now = new Date();
//    const expiration = new Date(new Date(SubmittedToVendorDate).getTime() + (OfferExpirationDays * 24 * 60 * 60 * 1000));
//    const timediff = expiration.getTime() - now.getTime();
//    return timediff < 0;
//}
export const expirationDateStatus = (SubmittedToVendorDate: string, OfferExpirationDays: number) => {
    const now = moment();
    const expiration = moment(SubmittedToVendorDate).add(OfferExpirationDays, 'days');
    debugger;
    return now.isAfter(expiration);
}
export const expirationDateTimeFormat = (SubmittedToVendorDate: string, OfferExpirationDays: number) => {
    const now = new Date();
    const expiration = new Date(new Date(SubmittedToVendorDate).getTime() + (OfferExpirationDays * 24 * 60 * 60 * 1000));
    const timeDiff = expiration.getTime() - now.getTime();
    const diffDays = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    return `${diffDays} d ${diffHours} h ${diffMinutes} m`;

}
export const expirationTimeFormat = (SubmittedToVendorDate: string, OfferExpirationDays: number) => {
    const expiration = new Date(new Date(SubmittedToVendorDate).getTime() + (OfferExpirationDays * 24 * 60 * 60 * 1000));
    return ` ${moment(expiration).format('MM-DD-YYYY HH:mm')}`;
}

