<template>
    <!--begin::Activities drawer-->
    <div id="kt_invoiceDiscountOfferPanel" class="bg-body"
         data-kt-drawer="true"
         data-kt-drawer-name="invoiceDiscountOfferPanel"
         data-kt-drawer-activate="true"
         data-kt-drawer-overlay="true"
         data-kt-drawer-width="{default:'400px', 'lg': '400px'}"
         data-kt-drawer-direction="end"
         data-kt-drawer-toggle="#kt_invoiceDiscountOfferPanel_toggle"
         data-kt-drawer-close="#kt_invoiceDiscountOfferPanel_close">
        <div class="card shadow-none  w-100">
            <div class="card-header" id="invoiceDiscountOfferPanel_header">
                <h3 class="card-title fw-bold text-dark">QP Rules</h3>
                <div class="card-toolbar">
                    <button type="button" class="btn btn-sm btn-icon btn-active-light-primary me-n5"
                            id="kt_invoiceDiscountOfferPanel_close">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </button>
                </div>
            </div>
            <div class="card-body pt-2" id="invoiceDiscountOfferPanel_body">

                <div v-if="isAddOfferFromIndex" class="row">
                    <div class="row">
                        <div class="col-6">
                            <div class="fw-semibold fs-7 text-gray-600 mb-1">Issue Date:</div>
                            <div class="mb-3 fw-bold fs-6 text-gray-800">{{ formatDate((invoice.IssueDate)) }}</div>

                            <div class="fw-semibold fs-7 text-gray-600 mb-1">Sub Total:</div>
                            <div class="mb-3 fw-bold fs-6 text-gray-800">{{ invoice?.SubTotal }}</div>

                            <div class="fw-semibold fs-7 text-gray-600 mb-1">Total Amount:</div>
                            <div class="mb-3 fw-bold fs-6 text-gray-800">{{ invoice?.TotalAmount }}</div>

                        </div>
                        <div class="col-6">
                            <div class="fw-semibold fs-7 text-gray-600 mb-1">Due Date:</div>
                            <div class="mb-3 fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
                                {{ formatDate(invoice.DueDate) }}
                            </div>

                            <div class="fw-semibold fs-7 text-gray-600 ">Issued By:</div>
                            <div class="mb-3 fw-bold fs-6 text-gray-800">{{ vendor?.Name }}</div>

                            <div class="fw-semibold fs-7 text-gray-600 ">Tax Amount:</div>
                            <div class="mb-3 fw-bold fs-6 text-gray-800">{{ invoice?.TaxAmount }}</div>
                        </div>
                    </div>
                </div>

                <div v-if="vendor?.QpRules?.length > 0">
                    <div v-for="(item, index) in vendor.QpRules" :key="index" class="mt-3">
                        <label class="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5">
                            <div class="d-flex align-items-center me-2">
                                <div class="form-check form-check-custom form-check-solid form-check-primary me-6">
                                    <input :id='"checkbox" + item.Id' @change="handleQPChange($event, item.Id)"
                                           class="form-check-input qpCheckbox"
                                           type="checkbox" v-bind:value="item.Id" />
                                </div>
                                <div class="flex-grow-1">
                                    <h2 class="d-flex align-items-center fs-3 fw-bold flex-wrap">
                                        {{ countDate(item.Days) }}
                                    </h2>
                                    <div class="fw-semibold opacity-50">
                                        {{ item.Days }} Days
                                    </div>
                                </div>
                            </div>
                            <div class="ms-5">
                                <span class="fs-2x fw-bold">
                                    {{ item.Discount.toTwoFractionDigits() }}
                                </span>
                                <span class="fs-7 opacity-50">
                                    /
                                    <span data-kt-element="period">%</span>
                                </span>
                            </div>
                        </label>
                    </div>
                    <button id="save_and_send_offer" disabled v-if="isAddOfferFromIndex" @click="saveAndSendOffer"
                            class="btn btn-warning">
                        Save and send
                    </button>
                    <button id="save_offer" disabled @click="submitOffer" class="btn btn-primary float-end">Save</button>

                </div>
                <div v-else>
                    <div class="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mt-5 h-md-50 mb-5 mb-xl-10"
                         style="background-color: #F1416C;background-image:url('/media/patterns/vector-1.png')">
                        <div class="card-body d-flex align-items-end ">
                            <div class="d-flex align-items-center flex-column mt-3 w-100">
                                <div class="d-flex justify-content-center fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2">
                                    <span>This Vendor does not have QP rules</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">

    import { computed, onMounted, defineComponent, PropType, ref, watch } from "vue";
    import { AddDiscountOfferCommand, ApiClient, DiscountOfferOptionDto, InvoiceVM, UpdateDiscountOfferCommand, VendorDto, } from "../../../clients/ApiClient";
    import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';
    import { useInvoiceStore } from "@/store/Invoice/InvoiceStore";
    import moment from "moment";
    import Swal from "sweetalert2/dist/sweetalert2.js";
    import { useVendorStore } from "@/store/vendor/VendorStore";
    import '../../extensions/numberExtension';
    import { dateFormat } from "@/extensions/dateExtension";

    export default defineComponent({
        name: "kt-invoiceDiscountOfferPanel-drawer",
        components: {},

        setup() {
            const invoiceStore = useInvoiceStore();
            const vendorStore = useVendorStore();
            const invoice = computed((): InvoiceVM => invoiceStore.invoiceState);
            const vendor = computed((): VendorDto => vendorStore.vendorState);
            const isEditOffer = computed((): boolean => invoiceStore.isEditOffer);
            const isAddOfferFromIndex = computed((): boolean => invoiceStore.isAddOfferFromIndex);
            const dueDate = invoice.value.DueDate;
            invoiceStore.setDataFromIndex();

            function countDate(days: number) {
                return moment(moment(Date.now()).add(days, 'd').format('MM-DD-YYYY')).format('MM-DD-YYYY');
            }


            let qpValues: string[] = [];
            const isValid = ref(false);

            function handleQPChange(event, id: string) {
                let checked = event.target.checked;
                if (checked) {
                    qpValues.push(id)
                } else {
                    const index = qpValues.indexOf(id);
                    qpValues.splice(index, 1);
                }
                if (qpValues.length > 0) {
                    document.querySelector("#save_offer")?.removeAttribute("disabled")
                    document.querySelector("#save_and_send_offer")?.removeAttribute("disabled")
                } else {
                    document.querySelector("#save_and_send_offer")?.setAttribute("disabled", "disabled")
                    document.querySelector("#save_offer")?.setAttribute("disabled", "disabled")


                }
            }

            async function submitOffer() {
                Swal.fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    reverseButtons: true
                }).then(async function (result) {
                    if (result.value) {

                        let addedDiscountOfferOptions = [] as Array<DiscountOfferOptionDto>;
                        let offerExpirationDays = vendor.value.OfferExpirationDays;
                        for (let i = 0; i <= qpValues.length; i++) {
                            let qp = vendor.value.QpRules!.find(qp => qp.Id === qpValues[i]);
                            if (qp !== undefined) {
                                addedDiscountOfferOptions.push({
                                    DiscountPercent: qp?.Discount,
                                    PaymentPeriodInDays: qp?.Days,
                                    QPRuleId: qp?.Id
                                });
                            }

                        }
                        const elem = document.getElementById('kt_invoiceDiscountOfferPanel_close');
                        elem?.click();
                        const listOfCheckbox = document.querySelectorAll('input[type=checkbox]');

                        let res = false;
                        if (isEditOffer.value) {
                            res = await invoiceStore.updateInvoiceOffer({
                                InvoiceId: invoice.value.Id,
                                discountOfferOptionDtos: addedDiscountOfferOptions,
                                DiscountOfferId: invoiceStore.editOfferId,
                            } as UpdateDiscountOfferCommand)
                        } else {
                            res = await invoiceStore.addInvoiceOffer({
                                InvoiceId: invoice.value.Id,
                                DiscountOfferOptions: addedDiscountOfferOptions,
                                OfferExpirationDays: offerExpirationDays,
                            } as AddDiscountOfferCommand)
                        }
                        invoiceStore.restoreUpdateOfferData()
                        Array.prototype.forEach.call(listOfCheckbox, function (checkbox) {
                            checkbox.checked = false;
                        });
                        qpValues = [];
                        // if (res) {
                        //   Swal.fire({
                        //     icon: 'success',
                        //     title: "Done",
                        //     showConfirmButton: false,
                        //     timer: 1500
                        //   });
                        // }
                    } else if (result.dismiss === "cancel") {
                        Swal.fire(
                            "Cancelled",
                        );
                    }
                });
            }


            async function saveAndSendOffer() {
                Swal.fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    reverseButtons: true
                }).then(async function (result) {
                    if (result.value) {
                        let addedDiscountOfferOptions = [] as Array<DiscountOfferOptionDto>;
                        let offerExpirationDays = vendor.value.OfferExpirationDays;
                        for (let i = 0; i <= qpValues.length; i++) {
                            let qp = vendor.value.QpRules!.find(qp => qp.Id === qpValues[i]);

                            if (qp !== undefined) {
                                addedDiscountOfferOptions.push({
                                    DiscountPercent: qp?.Discount,
                                    PaymentPeriodInDays: qp?.Days,
                                    QPRuleId: qp?.Id
                                });
                            }
                        }
                        const elem = document.getElementById('kt_invoiceDiscountOfferPanel_close');
                        elem?.click();

                        const res = await invoiceStore.addInvoiceOfferAndSend({
                            InvoiceId: invoice.value.Id,
                            DiscountOfferOptions: addedDiscountOfferOptions
                        })

                        invoiceStore.restoreUpdateOfferData()
                        const listOfCheckbox = document.querySelectorAll('input[type=checkbox]');
                        Array.prototype.forEach.call(listOfCheckbox, function (checkbox) {
                            checkbox.checked = false;
                        });
                        qpValues = [];
                        if (res) {
                            Swal.fire({
                                icon: 'success',
                                title: "Done",
                                showConfirmButton: false,
                                timer: 1500
                            });
                        }
                    } else if (result.dismiss === "cancel") {
                        Swal.fire(
                            "Cancelled",
                        );
                    }
                });
            }

            watch(() => invoiceStore.editOfferId, function () {
                if (isEditOffer.value) {
                    let offerId = invoiceStore.editOfferId;
                    let offer = invoice.value.InvoiceDiscountOffers?.find(x => x.Id === offerId);
                    if (offer && offer.InvoiceDiscountOfferOptions) {
                        for (let i = 0; i < offer.InvoiceDiscountOfferOptions.length; i++) {
                            qpValues.push(offer.InvoiceDiscountOfferOptions[i].QPRuleId!)
                            let checkbox = document.querySelector('#checkbox' + offer.InvoiceDiscountOfferOptions[i].QPRuleId!);
                            checkbox!['checked'] = true
                        }
                    }
                }
            });

            return {
                invoice,
                submitOffer,
                countDate,
                vendor,
                isEditOffer,
                handleQPChange,
                isAddOfferFromIndex,
                saveAndSendOffer,
                formatDate: dateFormat,
            }
        },
    });
</script>
