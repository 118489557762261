<template>
  <div :class="`card pt-2 ${cardClasses}`">
    <div class="card-header border-0">
      <div class="card-title">
        <h2>Invoices</h2>
      </div>
      <div class="card-toolbar m-0">
        <ul class="nav nav-stretch fs-5 fw-semobold nav-line-tabs nav-line-tabs-2x border-transparent" role="tablist">
          <li v-for="(item, index) in 3" :key="index" class="nav-item" role="presentation">
            <a v-if="item === 1" href="#" @click="getDataByYear(new Date().getFullYear())" class="nav-link text-active-primary active"
               data-bs-toggle="tab" role="tab">
              This Year 
            </a>
            <a v-else href="#" @click="getDataByYear(new Date().getFullYear() + 1 - item)" class="nav-link text-active-primary"
               data-bs-toggle="tab" role="tab">
              {{new Date().getFullYear()+1 - item}}
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body pt-0">
      <div id="kt_referred_users_tab_content" class="tab-content">
        <div class="py-0 tab-pane fade active show" role="tabpanel" >
          <Datatable 
              :table-header="tableHeader"
              :table-data="data"
              :rows-per-page="5"
              :enable-items-per-page-dropdown="false" >
            <template v-slot:cell-IssueDate="{ row: invoice }">
              {{ dateFormat(invoice.IssueDate) }}
            </template>
            <template v-slot:cell-DueDate="{ row: invoice }">
              {{ dateFormat(invoice.DueDate) }}
            </template>
            <template v-slot:cell-TotalAmount="{ row: invoice }">
              {{ invoice.TotalAmount }}
            </template>
            <template v-slot:cell-Currency="{ row: invoice }">
              {{ invoice.Currency }}
            </template>
            <template v-slot:cell-CurrencyRate="{ row: invoice }">
              {{ invoice.CurrencyRate }}
            </template>
            <template v-slot:cell-Status="{ row: invoice }">
              {{
                invoice.Status
              }}
            </template>
          </Datatable>
        </div>
        <div id="kt_customer_details_invoices_4" class="py-0 tab-pane fade" role="tabpanel">
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, onBeforeMount, onMounted, PropType, ref} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import {api} from "@/clients/ApiClientService";
import {
  ApiClient, InvoicesVM, InvoiceVM, VendorDto,
} from "../../../clients/ApiClient";
import {dateFormat} from "@/extensions/dateExtension";
import {useInvoiceStore} from "@/store/Invoice/InvoiceStore";
import {useVendorStore} from "@/store/vendor/VendorStore";
export default defineComponent({
  name: "invoices-table",
  props: {
    vendorId: {
      type: String,
      required: true
    },
    cardClasses: String,
  },
  components: {
    Datatable,
  },
  setup(props) {
    const invoiceStore = useInvoiceStore();
    const vendorStore = useVendorStore();
    const vendor = computed(() : VendorDto => vendorStore.vendorState);
    const invoiceFilteredByVendorAndYearState = computed(() : InvoicesVM => invoiceStore.invoiceFilteredByVendorAndYearState);
    const tableHeader = ref([
      {
        name: "Issue Date",
        key: "IssueDate",
        sortable: true,
      },
      {
        name: "Due Date",
        key: "DueDate",
        sortable: true,
      },
      {
        name: "Total Amount",
        key: "TotalAmount",
        sortable: true,
      },
      {
        name: "Currency",
        key: "Currency",
        sortable: true,
      },
      {
        name: "Currency Rate",
        key: "CurrencyRate",
        sortable: true,
      },
      {
        name: "Status",
        key: "Status",
        sortable: true,
        align: 'right',
      },
      // {
      //   name: "Status",
      //   key: "status",
      //   sortingField: "status.label",
      //   sortable: true,
      // },
    ]);
    const data = ref<InvoiceVM[]>([]);
    // data.value = invoiceFilteredByVendorAndYearState.value.Invoices;
    
    // onMounted(async () => {
    //   await getDataByYear(new Date().getFullYear())
    // });
    onBeforeMount (async () => {
      await getDataByYear(new Date().getFullYear())
    });
    
    async function getDataByYear (year:number){
      await invoiceStore.getInvoiceByVendorAndYear(props.vendorId, year);
      // let res = await api.invoices.getInvoices();
      data.value.splice(0);
      for (let i = 0; i < invoiceFilteredByVendorAndYearState.value.Invoices!.length; i++){
        data.value.push(invoiceFilteredByVendorAndYearState.value.Invoices![i]);
      }
    }
    return { tableHeader, data,  dateFormat,getDataByYear};
  },
});
</script>
