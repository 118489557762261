import {PluginOptions, useToast} from "vue-toastification";

const toast = useToast();
const options: PluginOptions = {
    timeout: 2000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false
};

export const successToast = function (message = "Successful done"){
    toast.success(message, options);
}
export const errorToast = function (message = "Something went wrong. Please try again or contact support"){
    toast.error(message, options);
}
