import { fa } from "element-plus/es/locale";
import { defineStore } from "pinia";
import { CompanyAccountingDetails } from "../../../clients/ApiClient";

export interface User {
    userId: string;
    nickname?: string;
    name?: string;
    company?: CompanyAccountingDetails;
    companyId?: string;
    vendorId?: string;
    logoutUrl?: string;
    isCompany: boolean;
    isInvitedUser: boolean;
    isVendor?: boolean;
}

export const useUserStore = defineStore('currentUserStore', {
    state: () => {
        return {
            userState: {} as User,
        }
    },

    actions: {
        setUserInfo(info: User) {
            this.$state.userState = info;
        },
        signoutClean() {
            this.$state.userState = { isCompany: false, userId: "", isInvitedUser: false };
            this.clearLocalStorage();
        },
        clearLocalStorage() {
            localStorage.clear();
        },

    },

    getters: {
        user: (state) => state,
    }
});
