/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum Role {
    NOT_SET = 'NotSet',
    USER = 'User',
    VIEWER = 'Viewer',
    ADMIN = 'Admin',
}
