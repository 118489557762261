/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';

import { AccountingService } from './services/AccountingService';
import { CompanyService } from './services/CompanyService';
import { IntegrationEventService } from './services/IntegrationEventService';
import { IntegrationEventMethodsService } from './services/IntegrationEventMethodsService';
import { IntegrationVendorEventService } from './services/IntegrationVendorEventService';
import { InvoicesService } from './services/InvoicesService';
import { QpRuleTemplateService } from './services/QpRuleTemplateService';
import { UserInvitationsService } from './services/UserInvitationsService';
import { VendorsService } from './services/VendorsService';
import { WebhooksService } from './services/WebhooksService';

type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;

export class ApiClient {

    public readonly accounting: AccountingService;
    public readonly company: CompanyService;
    public readonly integrationEvent: IntegrationEventService;
    public readonly integrationEventMethods: IntegrationEventMethodsService;
    public readonly integrationVendorEvent: IntegrationVendorEventService;
    public readonly invoices: InvoicesService;
    public readonly qpRuleTemplate: QpRuleTemplateService;
    public readonly userInvitations: UserInvitationsService;
    public readonly vendors: VendorsService;
    public readonly webhooks: WebhooksService;

    public readonly request: BaseHttpRequest;

    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });

        this.accounting = new AccountingService(this.request);
        this.company = new CompanyService(this.request);
        this.integrationEvent = new IntegrationEventService(this.request);
        this.integrationEventMethods = new IntegrationEventMethodsService(this.request);
        this.integrationVendorEvent = new IntegrationVendorEventService(this.request);
        this.invoices = new InvoicesService(this.request);
        this.qpRuleTemplate = new QpRuleTemplateService(this.request);
        this.userInvitations = new UserInvitationsService(this.request);
        this.vendors = new VendorsService(this.request);
        this.webhooks = new WebhooksService(this.request);
    }
}

