/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CompanyAccountingStatus {
    NOT_SET = 'NotSet',
    NO_COMPANY = 'NoCompany',
    ACCOUNTING_NOT_LINKED = 'AccountingNotLinked',
    ACCOUNTING_NO_CONNECTIONS = 'AccountingNoConnections',
    ACCOUNTING_CONNECTED = 'AccountingConnected',
    IS_VENDOR = 'IsVendor',
}
