/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum InvoiceStatus {
    NOT_SET = 'NotSet',
    DRAFT = 'Draft',
    OPEN = 'Open',
    PARTIALLY_PAID = 'PartiallyPaid',
    PAID = 'Paid',
    VOID = 'Void',
    UNKNOWN = 'Unknown',
}
