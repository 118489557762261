/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptUserInvitationCommand } from '../models/AcceptUserInvitationCommand';
import type { CheckIfInviteUserToCompanyCommand } from '../models/CheckIfInviteUserToCompanyCommand';
import type { DeleteInvitedUserCommand } from '../models/DeleteInvitedUserCommand';
import type { InviteUserToCompanyCommand } from '../models/InviteUserToCompanyCommand';
import type { UserInvitationsVm } from '../models/UserInvitationsVm';
import type { UserInvitationVm } from '../models/UserInvitationVm';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class UserInvitationsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public inviteUser(
        requestBody?: InviteUserToCompanyCommand,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/UserInvitations/Invite',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public acceptInvitation(
        requestBody?: AcceptUserInvitationCommand,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/UserInvitations/Accept',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns UserInvitationsVm Success
     * @throws ApiError
     */
    public getCompanyUserInvitations(): CancelablePromise<UserInvitationsVm> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/UserInvitations/GetCompanyUserInvitations',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteInvitedUser(
        requestBody?: DeleteInvitedUserCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/UserInvitations/Delete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns UserInvitationVm Success
     * @throws ApiError
     */
    public checkIfUserIsInvitedToCompany(
        requestBody?: CheckIfInviteUserToCompanyCommand,
    ): CancelablePromise<UserInvitationVm> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/UserInvitations/InviteUser/Checking',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
