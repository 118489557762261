import {defineStore} from "pinia";
import {
    AcceptedDiscountOfferCommand,
    ApiClient, ApiError,
    CreateQpRuleCommand, DeleteQpRuleCommand,
    DiscountOfferStatus,
    InsertQpRulesFromTemplateCommand,
    InvoiceVM,
    RejectedDiscountOfferCommand,
    UpdateQpRuleCommand,
    UpdateVendorCommand,
    VendorDto,
} from "../../../clients/ApiClient";
import {api} from "@/clients/ApiClientService";
import router from "@/router";
import {errorToast, successToast} from "@/helpers/customToast";


export const useVendorStore = defineStore('vendorStore',{
    state: ()  => {
        return {
            vendorState: Object as Partial<VendorDto>,
            invoiceWithOnlyApprovalOffersState: Object as Partial<InvoiceVM>,
        }
    },
    
    actions : {
        async getVendor <VendorDto>(vendorId) {
            const res = await api.vendors.getVendor(vendorId).resolve();
            if (res.result !== undefined){
                this.$state.vendorState = res.result;
            }
        },
        
        async getInvoiceWithOnlyApprovalOffers <InvoiceVM>(invoiceId) {
            const res = await api.invoices.get(invoiceId).resolve();
            if (res.result !== undefined){
                res.result.InvoiceDiscountOffers = res.result.InvoiceDiscountOffers?.filter(function(e){
                    return e.Status === DiscountOfferStatus.PENDING_APPROVAL
                        || e.Status === DiscountOfferStatus.REJECTED
                        || e.Status === DiscountOfferStatus.ACCEPTED;
                });
                this.$state.invoiceWithOnlyApprovalOffersState = res.result;
            }
        },
        
        async rejectOffer<bool> (command : RejectedDiscountOfferCommand) {
            const res = await api.invoices.rejectDiscountOffers(command).resolve();
            if (res.succeed){
                await this.getInvoiceWithOnlyApprovalOffers(command.InvoiceId);
                successToast();
            }else{
                errorToast();
            }
            return res.succeed;
        },


        async sendCredentials<bool> (vendorId : string) {
            
            const res = await api.vendors.sendCredentials(vendorId).resolve();
            if (res.succeed){
                successToast();
            }else{
                errorToast();
            }
            
            return res.succeed;
        },
        

        async editVendorDetails<bool> (command : UpdateVendorCommand) {
            const res = await api.vendors.updateVendor(command).resolve();
            if (res.succeed){
                await this.getVendor(command.Id);
                successToast();
            }else{
                errorToast();
            }
            return res.succeed;
        },
        
        async acceptOfferOption<bool> (command : AcceptedDiscountOfferCommand) {
            const res = await api.invoices.acceptOffer(command).resolve();
            if (res.succeed){
                await this.getInvoiceWithOnlyApprovalOffers(command.InvoiceId);
                successToast();
            }else{
                errorToast();
            }
            return res.succeed;
        },

        
        async addVendorQpRule<bool> (command : CreateQpRuleCommand) {
            const res = await api.vendors.createQpRule(command).resolve();
            if (res.succeed){
                await this.getVendor(command.VendorId);
                successToast();
            }else{
                errorToast();
            }
            return res.succeed;
        },
        
        async editVendorQpRule<bool> (command : UpdateQpRuleCommand) {
            const res = await api.vendors.updateQpRule(command).resolve();
            if (res.succeed){
                successToast();
                await this.getVendor(command.VendorId);
            }else{
                errorToast();
            }
            return res.succeed;
        },
        
        async addQPpRulesFromTemplate <bool>(command : InsertQpRulesFromTemplateCommand) {
            const res = await api.vendors.insertFromTemplate(command).resolve();
            if (res.succeed){           
                successToast();
                await this.getVendor(command.VendorId);
            }else{
                errorToast();
            }
            return res.succeed;
        },
        
        async deleteVendorQpRule<bool> (command : DeleteQpRuleCommand) {
            const res = await api.vendors.deleteQpRule(command).resolve();
            if (res.succeed){
                successToast();
                await this.getVendor(command.VendorId);
            }else{
                errorToast();
            }
            return res.succeed;
        },
    },
    getters : {
        invoice: (state) => state,
    }
});
