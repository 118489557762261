<template>
	<div class="card mb-6 mb-xl-9">
		<div class="card-body pt-9 pb-0">
			<div class="row">
				<div class="col-4">
					<div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
						<div class="d-flex flex-column">
							<div class="d-flex align-items-center mb-1">
								<router-link :to="`/Vendors/${vendor?.Id}`">
									<a href="#" class="text-gray-800 text-hover-primary fs-2 fw-bold me-3">
										Issued By:
										{{ vendor?.Name }}
									</a>
								</router-link>
								<span class="badge badge-light-success me-auto"> </span>
							</div>
							<div class="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">
								Note -
								{{ invoice.Note }}
							</div>
						</div>
					</div>
				</div>
				<div class="col-8">
					<div class="d-flex flex-wrap">

						<div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
							<div class="d-flex align-items-center">
								<div class="fs-4 fw-bold">{{ invoice.Status }}</div>
							</div>
							<div class="fw-semibold fs-6 text-gray-400">Status</div>
						</div>

						<div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
							<div class="d-flex align-items-center">
								<div class="fs-4 fw-bold">{{ formatDate(invoice.IssueDate) }}</div>
							</div>
							<div class="fw-semibold fs-6 text-gray-400">Issue Date</div>
						</div>

						<div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
							<div class="d-flex align-items-center">
								<div class="fs-4 fw-bold">{{ formatDate(invoice.DueDate) }}</div>
							</div>
							<div class="fw-semibold fs-6 text-gray-400">Due Date</div>
						</div>

						<div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
							<div class="d-flex align-items-center">
								<div class="fs-4 fw-bold">Total Amount</div>
							</div>
							<div class="fw-semibold fs-6 text-gray-400">{{ invoice.TotalAmount }}</div>
						</div>

						<div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
							<div class="d-flex align-items-center">
								<div class="fs-4 fw-bold">Sub Total</div>
							</div>
							<div class="fw-semibold fs-6 text-gray-400">{{ invoice.SubTotal }}</div>
						</div>


					</div>
				</div>
			</div>
			<div class="separator"></div>
			<ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semobold ">
				<li class="nav-item">
					<a class="nav-link text-active-primary  active" data-bs-toggle="tab"
					   href="#invoice_details">Overview</a>
				</li>
				<li class="nav-item">
					<a class="nav-link text-active-primary " data-bs-toggle="tab"
					   href="#offers">Offers</a>
				</li>
			</ul>
		</div>
	</div>
	<div class="tab-content" id="myTabContent">
		<div class="tab-pane fade show active" id="invoice_details" role="tabpanel">
			<div class="card mb-6 mb-xl-9">
				<div class="card-body">
					<div class="d-flex flex-column flex-xl-row">
						<div class="flex-lg-row-fluid me-xl-18 mb-10 mb-xl-0">
							<div class="mt-n1">
								<div class="m-0">
									<div class="row">
										<div class="col-sm-6 mt-5">
											<div class="row">
												<div class="col-6">
													<div class="fw-semibold fs-7 text-gray-600 mb-1">Issue Date:</div>
													<div class="mb-5 fw-bold fs-6 text-gray-800">{{ formatDate((invoice.IssueDate)) }}</div>

													<div class="fw-semibold fs-7 text-gray-600 mb-1">Issue For:</div>
													<div class="fw-bold fs-6 text-gray-800">Company name.</div>

												</div>
												<div class="col-6">
													<div class="fw-semibold fs-7 text-gray-600 mb-1">Due Date:</div>
													<div class="mb-5 fw-bold fs-6 text-gray-800 d-flex align-items-center flex-wrap">
														{{ formatDate(invoice.DueDate) }}
													</div>
													<div class="fw-semibold fs-7 text-gray-600 mt-5">Issued By:</div>
													<div class="fw-bold fs-6 text-gray-800">{{ vendor?.Name }}</div>
												</div>
											</div>
										</div>
										<div class="col-sm-6">
											<div class="row d-print-none border border-dashed border-gray-300 card-rounded h-lg-100 min-w-md-300px p-9 bg-lighten">
												<div class="col-6">
													<h6 class="mb-8 fw-bolder text-gray-600 text-hover-primary">INVOICE DETAILS</h6>
													<div class="mb-8">
														<span class="fs-4 badge badge-light-success me-2">{{ invoice.Status }}</span>
													</div>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Sub Total: <span style="margin-left: 5px"
											   class="fw-bold fs-6 text-gray-800">{{ invoice.SubTotal }}</span>
														</div>
													</div>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Tax Amount: <span style="margin-left: 5px"
												class="fw-bold fs-6 text-gray-800">{{ invoice.TaxAmount }}</span>
														</div>
													</div>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Total Amount: <span style="margin-left: 5px"
												  class="fw-bold fs-6 text-gray-800">{{ invoice.TotalAmount }}</span>
														</div>
													</div>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Amount Due: <span style="margin-left: 5px"
												class="fw-bold fs-6 text-gray-800">{{ invoice.AmountDue }}</span>
														</div>
													</div>
												</div>
												<div class="col-6">
													<h6 class="mb-8 fw-bolder text-gray-600 text-hover-primary">RATE DETAILS</h6>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Currency: <span style="margin-left: 5px"
															 class="fw-bold text-gray-800 fs-6"> {{ invoice.Currency }}</span>
														</div>
													</div>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Currency Rate: <span style="margin-left: 5px"
												   class="fw-bold text-gray-800 fs-6">{{ invoice.CurrencyRate }}</span>
														</div>
													</div>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Tax Rate Name: <span style="margin-left: 5px"
									class="fw-bold text-gray-800 fs-6"> {{ invoice.TaxRateName }}</span>
														</div>
													</div>
													<div class="mb-5">
														<div class="fw-semibold text-gray-600 fs-7">
															Tax Rate Percent: <span style="margin-left: 5px"
									   class="fw-bold text-gray-800 fs-6"> {{ invoice.TaxRatePercent }}</span>
														</div>
													</div>
												</div>
												<div class="col-12">
													<div class="fw-semibold text-gray-600 fs-7">
														Note: <span style="margin-left: 5px"
													  class="fw-bold text-gray-800 fs-6"> {{ invoice.Note }}</span>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="flex-grow-1">
										<div class="table-responsive border-bottom mb-9">
											<table class="table mb-3">
												<thead>
													<tr class="border-bottom fs-6 fw-bold text-muted">
														<th class=" pb-2">Description</th>
														<th class=" text-end pb-2">Unit Amount</th>
														<th class=" text-end pb-2">Quantity</th>
														<th class=" text-end pb-2">Sub Total</th>
														<th class=" text-end pb-2">Total Amount</th>
														<th class=" text-end pb-2">Tax Amount</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(item, index) in invoice.InvoiceLineItems" :key="index"
														class="fw-bold text-gray-700 fs-5 text-end">
														<td class="text-start">{{ item.Description }}</td>
														<td class="">{{ item.UnitAmount }}</td>
														<td>{{ item.Quantity }}</td>
														<td>{{ item.SubTotal }}</td>
														<td>{{ item.TotalAmount }}</td>
														<td class="fs-5 text-dark fw-bolder">{{ item.TaxAmount }}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="tab-pane fade" id="offers" role="tabpanel">
			<InvoiceOffers />
		</div>
	</div>

</template>
<script lang="ts">
	import { computed, defineComponent, onMounted, ref } from "vue";
	import { ApiClient, InvoiceVM, VendorDto } from "../../../clients/ApiClient";
	import { InvoiceStatus } from "../../../clients/ApiClient";
	import { dateFormat } from "@/extensions/dateExtension";
	import InvoiceOffers from "@/components/invoice/InvoiceOffers.vue";
	import InvoiceDiscountOfferPanel from "@/components/invoice/InvoiceDiscountOfferPanel.vue";
	import { useInvoiceStore } from "@/store/Invoice/InvoiceStore";
	import { useVendorStore } from "@/store/vendor/VendorStore";
	import { useUserStore } from "@/store/currentUser/CurrentUserStore";
	import router from "@/router";

	export default defineComponent({
		name: "InvoicesDetails",
		components: {
			InvoiceOffers,
		},

		props: ['id'],
		setup(props) {
			const userStore = useUserStore();
			if (!userStore.userState.isCompany && !userStore.userState.isInvitedUser) {
				router.push("/404");
			}
			const activeTab = ref('invoice_details');

			const invoiceStore = useInvoiceStore();
			const vendorStore = useVendorStore();
			const invoice = computed((): InvoiceVM => invoiceStore.invoiceState);
			const vendor = computed((): VendorDto => vendorStore.vendorState);
			onMounted(async () => {
				await invoiceStore.getInvoice(props.id);
				await vendorStore.getVendor(invoice.value.SupplierId);
				activeTab.value = 'invoice_details';
			});
			return {
				activeTab,
				invoice,
				vendor,
				InvoiceStatus, onMounted,
				formatDate: dateFormat,
			}
		},
	});
</script>