/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompanyAccountingDetails } from '../models/CompanyAccountingDetails';
import type { RegisterCompanyInAccountingSystemCommand } from '../models/RegisterCompanyInAccountingSystemCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AccountingService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns CompanyAccountingDetails Success
     * @throws ApiError
     */
    public registerCompany(
        requestBody?: RegisterCompanyInAccountingSystemCommand,
    ): CancelablePromise<CompanyAccountingDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Accounting',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param companyId
     * @returns CompanyAccountingDetails Success
     * @throws ApiError
     */
    public getCompanyAccountingDetails(
        companyId: string,
    ): CancelablePromise<CompanyAccountingDetails> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/Accounting/{companyId}',
            path: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @param companyId
     * @returns CompanyAccountingDetails Success
     * @throws ApiError
     */
    public syncCompanyStatus(
        companyId: string,
    ): CancelablePromise<CompanyAccountingDetails> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Accounting/Sync/{companyId}',
            path: {
                'companyId': companyId,
            },
        });
    }

    /**
     * @param companyId
     * @returns any Success
     * @throws ApiError
     */
    public refreshData(
        companyId?: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/Accounting/RefreshData',
            query: {
                'companyId': companyId,
            },
        });
    }

}
