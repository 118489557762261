/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddQpRuleOptionsCommand } from '../models/AddQpRuleOptionsCommand';
import type { CreateQpRuleTemplateCommand } from '../models/CreateQpRuleTemplateCommand';
import type { DeleteQpRuleOptionsCommand } from '../models/DeleteQpRuleOptionsCommand';
import type { DeleteQpRuleTemplateCommand } from '../models/DeleteQpRuleTemplateCommand';
import type { QpRuleTemplatesVM } from '../models/QpRuleTemplatesVM';
import type { QpRuleTemplateVM } from '../models/QpRuleTemplateVM';
import type { UpdateQPRuleTemplateCommand } from '../models/UpdateQPRuleTemplateCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class QpRuleTemplateService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns QpRuleTemplateVM Success
     * @throws ApiError
     */
    public createQpRuleTemplate(
        requestBody?: CreateQpRuleTemplateCommand,
    ): CancelablePromise<QpRuleTemplateVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/QpRuleTemplate',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns QpRuleTemplateVM Success
     * @throws ApiError
     */
    public updateQpRuleTemplateOptions(
        requestBody?: UpdateQPRuleTemplateCommand,
    ): CancelablePromise<QpRuleTemplateVM> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/Api/V1/QpRuleTemplate',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns QpRuleTemplateVM Success
     * @throws ApiError
     */
    public addQpRuleOption(
        requestBody?: AddQpRuleOptionsCommand,
    ): CancelablePromise<QpRuleTemplateVM> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/QpRuleTemplate/Options',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteQpRuleoption(
        requestBody?: DeleteQpRuleOptionsCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/QpRuleTemplate/Options/Delete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns boolean Success
     * @throws ApiError
     */
    public deleteQpRuleTemplate(
        requestBody?: DeleteQpRuleTemplateCommand,
    ): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/QpRuleTemplate/Delete',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param id
     * @returns QpRuleTemplateVM Success
     * @throws ApiError
     */
    public getTemplateId(
        id: string,
    ): CancelablePromise<QpRuleTemplateVM> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/QpRuleTemplate/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns QpRuleTemplatesVM Success
     * @throws ApiError
     */
    public getAllTemplates(): CancelablePromise<QpRuleTemplatesVM> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/Api/V1/QpRuleTemplate/All',
        });
    }

}
