<template>

    <div class="card mt-5 mb-xl-10">
        <div class="card-header">
            <div class="card-title">
                <h3>Offers</h3>
            </div>
            <div class="card-toolbar">
                <button v-if="canAddOffer " class="btn btn-primary" id="kt_invoiceDiscountOfferPanel_toggle">Add Offer</button>
            </div>
        </div>
        <div class="card-body">
            <div class="accordion" id="kt_accordion">
                <div class="accordion-item"
                     v-for="(item, index) in invoice.InvoiceDiscountOffers"
                     :key="index">
                    <h2 class="accordion-header" :id="'kt_accordion_'+index+'_header_'+index">
                        <button class="accordion-button  fw-bold collapsed" type="button" aria-expanded="false"
                                data-bs-toggle="collapse"
                                :data-bs-target="'#kt_accordion_'+index+'_body_'+index"
                                :aria-controls="'kt_accordion_'+index+'_body_'+index">
                            <span class="me-5 me-4 min-w-200px" v-html="getDiscountOfferStatusLabel(item.Status)" style="min-width: 10em"></span>
                            <span class="fs-5 me-5 min-w-150px">Date: <span class="fs-5 badge badge-light-primary">{{dateTimeFormat(item.Created)}}</span></span>
                            <div v-if="item.Status != DiscountOfferStatus.DRAFT">
                                <div v-if="item.Status === DiscountOfferStatus.CANCELLED">
                                    <span class="fs-5 me-4"> {{toTitleCase(DiscountOfferStatus.CANCELLED)}} <span class="fs-5 badge badge-light-danger">{{dateTimeFormat(item.SubmittedToVendorDate,item.OfferExpirationDays)}}</span></span>
                                </div>
                                <div v-else-if="item.Status=== DiscountOfferStatus.EXPIRED && item.Status === DiscountOfferStatus.PENDING_APPROVAL">
                                    <span class="fs-5 me-4"> {{toTitleCase(DiscountOfferStatus.EXPIRED)}} <span class="fs-5 badge badge-light-danger">{{expirationTimeFormat(item.SubmittedToVendorDate,item.OfferExpirationDays)}}</span></span>
                                </div>
                                <div v-else-if="item.Status === DiscountOfferStatus.PENDING_APPROVAL">
                                    <span class="fs-5 me-4 min-w-150px">Expiration in <span class="fs-5 badge badge-light-primary">{{expirationDateTimeFormat(item.SubmittedToVendorDate,item.OfferExpirationDays)}}</span></span>
                                </div>
                            </div>


                        </button>
                    </h2>
                    <div :id="'kt_accordion_'+index+'_body_'+index"
                         class="accordion-collapse collapse "
                         :aria-labelledby="'kt_accordion_'+index+'_header_'+index"
                         :data-bs-parent="'#kt_accordion'">
                        <div class="accordion-body">
                            <div class="row">
                                <div class="col-9">
                                    <div class="row mb-2" v-for="(option, optIndex) in item.InvoiceDiscountOfferOptions"
                                         :key="optIndex">
                                        <div class="col-3">
                                            <span class="text-gray-600">Payment Date - </span> <span class="fw-bold mt-5"> {{ countSubmitedDate(option.PaymentPeriodInDays,item) }}</span>
                                        </div>
                                        <div class="col-3">
                                            <span class="text-gray-600">Discount Percent - </span> <span class="fw-bold mt-5"> {{ option.DiscountPercent.toTwoFractionDigits() }} %</span>
                                        </div>
                                        <div class="col-3">
                                            <span class="text-gray-600">New Total Amount - </span> <span class="fw-bold mt-5"> {{ countNewTotalAmount(option.DiscountPercent) }} </span>
                                        </div>
                                        <div class="col-2">
                                            <span class="fw-bold mt-5 badge-light-success fs-4" v-if="option.IsAccepted">Accepted</span>
                                        </div>
                                        <hr>
                                    </div>
                                </div>

                                <div class="col-3">
                                    <button v-if="item.Status === DiscountOfferStatus.PENDING_APPROVAL" @click="cancelOffer(item.Id)"
                                            class="btn btn-danger float-end">
                                        Cancel
                                    </button>
                                    <div v-if="item.Status === DiscountOfferStatus.DRAFT">
                                        <button @click="sendOfferForApproval(item.Id)"
                                                class="btn btn-warning float-end">
                                            Send
                                        </button>
                                        <button @click="setUpdateOfferData(item.Id)" class="btn btn-primary me-4 float-end"
                                                id="kt_invoiceDiscountOfferPanel_toggle">
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="invoice?.InvoiceDiscountOffers?.length === 0">
                <div class="alert alert-danger p-5 text-center">
                    <h4 class="mb-1 text-dark">No Data</h4>
                    <!--              <span>If you want add offer please click "Add Offer" button and choose the offer options.</span>-->
                </div>
            </div>

        </div>
    </div>
    <InvoiceDiscountOfferPanel />
</template>

<script lang="ts">
    import { computed, defineComponent, ref } from "vue";
    import { DiscountOfferStatus, InvoiceVM, InvoiceDiscountOfferVM } from "../../../clients/ApiClient";
    import moment from "moment";
    import InvoiceDiscountOfferPanel from "@/components/invoice/InvoiceDiscountOfferPanel.vue";
    import { useInvoiceStore } from "@/store/Invoice/InvoiceStore";
    import Swal from "sweetalert2/dist/sweetalert2.js";
    import { getDiscountOfferStatusLabel } from "@/extensions/statusExtension";
    import { toTitleCase } from "@/helpers/TextConvert";
    import '../../extensions/numberExtension';
    import { dateFormat, expirationDateTimeFormat, expirationDateStatus, expirationTimeFormat, dateTimeFormat } from "@/extensions/dateExtension";
    import { es } from "element-plus/es/locale";
    export default defineComponent({
        name: "invoice-offers",
        components: {
            InvoiceDiscountOfferPanel
        },
        setup() {
            const invoiceStore = useInvoiceStore();
            const invoice = computed((): InvoiceVM => invoiceStore.invoiceState)
            const canAddOffer = computed((): boolean => invoiceStore.canAddOffer)
            const canEditOffer = computed((): boolean => invoiceStore.canEditOffer)
            const isEditOffer = computed((): boolean => invoiceStore.isEditOffer)

            const swalOptions = {
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                reverseButtons: true
            };
            const successSwal = function () {
                Swal.fire({
                    icon: 'success',
                    title: "Done",
                    showConfirmButton: false,
                    timer: 1500
                });
            }
            const errorSwal = function () {
                Swal.fire({
                    icon: 'error',
                    title: 'Something went wrong!',
                    position: 'top-end',
                    text: 'Possible reason payment date',
                    showConfirmButton: true,
                    timer: 10000
                });
            }

            async function cancelOffer(offerId: string) {
                Swal.fire(swalOptions).then(async function (result) {
                    if (result.value) {
                        let res = await invoiceStore.cancelInvoiceOffer({ CanceledOffer: offerId, InvoiceId: invoice.value.Id });
                        // if (res) {
                        //   successSwal()
                        // }
                    } else if (result.dismiss === "cancel") {
                        Swal.fire(
                            "Cancelled",
                        );
                    }
                });
            }

            async function sendOfferForApproval(offerId: string) {
                Swal.fire(swalOptions).then(async function (result) {
                    debugger;
                    if (result.value) {
                        let res = await invoiceStore.sendOfferForApproval({ SentOfferId: offerId, InvoiceId: invoice.value.Id });

                        if (res) {
                            successSwal()
                        } else {
                            errorSwal()
                        }
                    } else if (result.dismiss === "cancel") {
                        Swal.fire(
                            "Cancelled",
                        );
                    }
                });
            }

            function setUpdateOfferData(offerId: string) {
                invoiceStore.setUpdateOfferData(offerId);
            }


            function countSubmitedDate(days: number, offer: InvoiceDiscountOfferVM) {
                if (offer?.Status === DiscountOfferStatus?.DRAFT) {
                    return moment(moment(Date.now()).add(days, 'd').format('MM-DD-YYYY')).format('MM-DD-YYYY');
                }
                else {
                    return moment((moment(offer?.SubmittedToVendorDate)).add(days, 'd').format('MM-DD-YYYY')).format('MM-DD-YYYY');

                }
            }


            function countNewTotalAmount(discountPercent: number) {
                if (invoice.value.TotalAmount) {
                    return (invoice.value.TotalAmount - (invoice.value.TotalAmount * discountPercent) / 100).toFixed(1)
                }
                return "N/A";
            }

            return {
                toTitleCase,
                dateFormat,
                moment,
                expirationTimeFormat,
                expirationDateStatus,
                expirationDateTimeFormat,
                dateTimeFormat,
                cancelOffer,
                sendOfferForApproval,
                countNewTotalAmount,
                invoice,
                getDiscountOfferStatusLabel,
                canAddOffer,
                setUpdateOfferData,
                canEditOffer,
                isEditOffer,
                DiscountOfferStatus,
                countSubmitedDate,
            };
        },
    });
</script>