/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultQpRuleTemplate } from '../models/DefaultQpRuleTemplate';
import type { VendorImported } from '../models/VendorImported';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IntegrationVendorEventService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createdVendor(
        requestBody?: VendorImported,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationVendorEvent/VendorCreated',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public defaultQpRuleTemplateCreate(
        requestBody?: DefaultQpRuleTemplate,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationVendorEvent/DefaultQpRuleTemplateCreate',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public updatedVendor(
        requestBody?: VendorImported,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationVendorEvent/VendorUpdated',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
