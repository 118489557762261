/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DefaultQpRulesSendingEvent } from '../models/DefaultQpRulesSendingEvent';
import type { InvoiceImported } from '../models/InvoiceImported';
import type { QpRuleDeletedEvent } from '../models/QpRuleDeletedEvent';
import type { QpRuleUpdatedEvent } from '../models/QpRuleUpdatedEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IntegrationEventService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public qpRuleDeleted(
        requestBody?: QpRuleDeletedEvent,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationEvent/QpRuleDeleted',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public qpRuleUpdated(
        requestBody?: QpRuleUpdatedEvent,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationEvent/QpRuleUpdated',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public createdInvoice(
        requestBody?: InvoiceImported,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationEvent/InvoiceCreated',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public updatedInvoice(
        requestBody?: InvoiceImported,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationEvent/InvoiceUpdated',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public sendDefaultDiscountOffer(
        requestBody?: DefaultQpRulesSendingEvent,
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationEvent/DefaultQpRulesSendingEvent',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
