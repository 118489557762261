/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum DiscountOfferStatus {
    NOT_SET = 'NotSet',
    DRAFT = 'Draft',
    PENDING_APPROVAL = 'PendingApproval',
    REJECTED = 'Rejected',
    ACCEPTED = 'Accepted',
    CANCELLED = 'Cancelled',
    EXPIRED = 'Expired',
}
