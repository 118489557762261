<template>
  <div class="tab-pane fade" id="qp-rules" role="tabpanel">
    <div :class="`card pt-4 `">
      <div class="card-header border-0">
        <div class="card-title">
          <h2 class="fw-bold mb-0">QP Rules</h2>

        </div>
        <div class="card-toolbar">
          <button @click="addNewQpRule" class="btn btn-sm btn-flex btn-light-primary me-2"
                  data-bs-toggle="modal"
                  data-bs-target="#addRulesFromTemplate">
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="/media/icons/duotune/general/gen035.svg"/>
                    </span>
            Add QP rules from template
          </button>

          <button @click="addNewQpRule" class="btn btn-sm btn-flex btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_new_card">
                    <span class="svg-icon svg-icon-3">
                      <inline-svg src="/media/icons/duotune/general/gen035.svg"/>
                    </span>
            Add new QP rule
          </button>
        </div>
      </div>
      <div id="kt_customer_view_payment_method" class="card-body pt-0">
        <div v-for="(item, index) in vendor.QpRules" v-bind:key="index">
          <div class="py-0" data-kt-customer-payment-method="row">
            <div class="py-3 d-flex flex-stack flex-wrap">
              <div class="d-flex align-items-center collapsible rotate collapsed"
                   data-bs-toggle="collapse" href="#kt_customer_view_payment_method_1"
                   role="button" aria-expanded="false"
                   aria-controls="kt_customer_view_payment_method_1">
                <div class="me-3">
                  <div class="d-flex align-items-center">
                    <div class="text-gray-800 fw-bold">Days</div>
                  </div>
                  <div class="text-muted">{{ item.Days }}</div>
                </div>
              </div>
              <div class="d-flex align-items-center collapsible rotate collapsed"
                   data-bs-toggle="collapse" href="#kt_customer_view_payment_method_1"
                   role="button" aria-expanded="false"
                   aria-controls="kt_customer_view_payment_method_1">
                <div class="me-3">
                  <div class="d-flex align-items-center">
                    <div class="text-gray-800 fw-bold">Discount</div>
                  </div>
                  <div class="text-muted">{{ item.Discount.toTwoFractionDigits() }} %</div>
                </div>
              </div>
              <div class="d-flex my-3 ms-9">
                <a href="#" @click="updateQp(item)" class="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                   data-bs-toggle="modal" data-bs-target="#kt_modal_new_card">
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="" data-bs-original-title="Edit"> 
                          <span class="svg-icon svg-icon-3">
                            <inline-svg src="/media/icons/duotune/art/art005.svg"/>
                          </span>
                        </span>
                </a>
                <a href="#" @click="deleteQpRule(item.Id)" class="btn btn-icon btn-active-light-primary w-30px h-30px me-3"
                   data-bs-toggle="tooltip"
                   title="" data-kt-customer-payment-method="delete"
                   data-bs-original-title="Delete">
                <span class="svg-icon svg-icon-3">
                  <inline-svg src="/media/icons/duotune/general/gen027.svg"/>
                </span>
                </a>
              </div>
            </div>
            <div id="kt_customer_view_payment_method_1"
                 class="fs-6 ps-10 collapse"
                 data-bs-parent="#kt_customer_view_payment_method"
                 style="">
            </div>
          </div>
          <div class="separator separator-dashed"></div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="addRulesFromTemplate" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-850px">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Add QP rules from templates</h2>
            <div id="closeQpRuleTemplateModal" class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
            <Form id="addRulesFromTemplate_form" class="form">
              <div class="d-flex flex-column mb-7">
                <div v-if="templatesStore?.QpRuleTemplates?.length !== 0" class="row">
                  <div class="col-6" v-for="(item,index) in templatesStore.QpRuleTemplates" :key="index" >
                    <label class="btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5">
                      <div class="d-flex align-items-center me-2">
                        <div class="form-check form-check-custom form-check-solid form-check-primary me-6">
                          <input class="form-check-input qpRuleTemplateRadio" type="radio" name="template" :value="item.Id">
                        </div>
                        <div class="flex-grow-1">
                          <h2 class="d-flex align-items-center fs-3 fw-bold flex-wrap ">{{item?.Name}}</h2>
                          <div class="fw-semibold opacity-50">Total Options Count {{item?.QpRuleTemplateOptions?.length}}</div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div v-else class="row">
                  <div class="col-12">
                    <div class="alert alert-danger p-5 text-center">
                      <h4 class="mb-1 text-dark">No Data</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div  v-if="templatesStore?.QpRuleTemplates?.length !== 0" class="text-center pt-15">
                <button @click="submitAdditionQpRulesFromTemplate" type="button" id="addRulesFromTemplate_submit" class="btn btn-primary">
                  <span class="indicator-label"> Submit </span>
                  <span class="indicator-progress">
                         Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade"  id="kt_modal_new_card" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered mw-650px">
        <div class="modal-content">
          <div class="modal-header">
            <h2>Qp Rule Form</h2>
            <div id="closeQpModal" class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
              <span class="svg-icon svg-icon-1">
                <inline-svg src="/media/icons/duotune/arrows/arr061.svg"/>
              </span>
            </div>
          </div>
          <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

            <Form @submit="submitQpRules"
                  :validation-schema="validationSchema"
                  id="kt_modal_new_card_form" class="form">
              <div class="d-flex flex-column mb-7">
                <div class="row">
                  <div class="col-6">
                    <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                      <span class="required">Days</span>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                         title="Specify a card holder's name"
                      ></i>
                    </label>
                    <Field id="qpDays" @input="event => handleDaysChange(qpRule?.Id, event.target.value)"
                           type="text" class="form-control form-control-solid" placeholder=""
                           name="qpDays" :modelValue="qpRule?.Days" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="qpDays" />
                      </div>
                    </div>

                  </div>
                  <div class="col-6">
                    <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                      <span class="required">Discount</span>
                      <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                         title="Specify a card holder's name"
                      ></i>
                    </label>
                    <Field id="qpDiscount" @input="event => handleDiscountChange(qpRule?.Id, event.target.value)"
                           type="text" class="form-control form-control-solid" placeholder=""
                           name="qpDiscount" :modelValue="qpRule?.Discount" />

                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="qpDiscount" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center pt-15">
                <button ref="submitButtonRef" type="submit"
                        id="kt_modal_new_address_submit"  class="btn btn-primary" >
                  <span class="indicator-label"> Submit </span>
                  <span class="indicator-progress">
                      Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2" ></span>
                    </span>
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import Swal from "sweetalert2/dist/sweetalert2.js"
import {computed, defineComponent, onMounted, ref} from "vue";
import {QpRuleDto, VendorDto,} from "../../../clients/ApiClient";
import {useQPTemplateStore} from "@/store/QPRuleTemplate/QPRuleTemplateStore";
import {ErrorMessage, Field, Form} from "vee-validate";
import * as Yup from "yup";
import {useVendorStore} from "@/store/vendor/VendorStore";
import {QpRuleTemplatesVM} from "@/models/QpRuleTemplates/QpRuleTemplatesVM";
import '../../extensions/numberExtension';

export default defineComponent({
  name: "qp-rules",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
  },
  setup() {
    const QPTemplateStore = useQPTemplateStore();
    const vendorStore = useVendorStore();
    const vendor = computed(() : VendorDto => vendorStore.vendorState);
    const templatesStore = computed((): QpRuleTemplatesVM => QPTemplateStore.QPTemplatesState)
    onMounted(async () => {
      await QPTemplateStore.getAllQPTemplates();
    });
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const validationSchema = Yup.object().shape({
      qpDays: Yup.number().min(1).required().label("Days"),
      qpDiscount: Yup.number().min(1).max(100).required().label("Discount"),
    });


    const swalOpt = {
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      reverseButtons: true
    }
    const successSwalOpt = {
      icon: 'success',
      title: "Done",
      showConfirmButton: false,
      timer: 1500
    }

    const qpRule = ref<QpRuleDto>({});
    
    const newQpRule = ref<QpRuleDto>({
      Days: 0,
      Discount: 0
    });

    let submitIsNew = false;

    function updateQp(item) {
      submitIsNew = false
      if (!item) return;
      qpRule.value = { Id : item.Id, VendorId : item.VendorId, Days : item.Days, Discount: item.Discount };
    }

    function handleDaysChange(id: string, days: string) {
      const rule = vendor.value.QpRules?.find(x => x.Id === id);
      if (rule) {
        qpRule.value.Days = parseInt(days);
      } else {
        newQpRule.value.Days = parseInt(days);
      }
    }

    function handleDiscountChange(id: string, discount: string) {
      const rule = vendor.value.QpRules?.find(x => x.Id === id);
      if (rule) {
        qpRule.value.Discount = parseFloat(discount);
      } else {
        newQpRule.value.Discount = parseFloat(discount);
      }
    }
    function addNewQpRule() {
      submitIsNew = true;
    }


    async function submitQpRules() {
      Swal.fire(swalOpt).then(async function (result) {
        if (result.value) {
          const elem = document.getElementById('closeQpModal');
          elem?.click();
          if (submitIsNew) {
              await vendorStore.addVendorQpRule({
              VendorId: vendor.value.Id,
              Days : newQpRule.value.Days,
              Discount: newQpRule.value.Discount
            });
            newQpRule.value = {};
          } else{
              await vendorStore.editVendorQpRule({
              VendorId: vendor.value.Id,
              QpRuleId: qpRule.value.Id,
              Days: qpRule.value.Days,
              Discount: qpRule.value.Discount
            });
          }

          const listOfInputs = document.querySelectorAll('input[type=text]');
          Array.prototype.forEach.call(listOfInputs, function (input) {
            input.value = '';
          });
          // if (qpRuleResult){
          //   Swal.fire(successSwalOpt);
          // }
        } else if (result.dismiss === "cancel") {
          Swal.fire( "Cancelled", );
        }
      });
    }

    async function submitAdditionQpRulesFromTemplate() {
      Swal.fire(swalOpt).then(async function (result) {
        if (result.value) {
          const selectedQpRuleTemplateId = document.querySelector('input[name="template"]:checked')?.['value'];
          const elem = document.getElementById('closeQpRuleTemplateModal');
          elem?.click();
          await vendorStore.addQPpRulesFromTemplate({VendorId : vendor.value.Id!, TemplateId : selectedQpRuleTemplateId} );
        } else if (result.dismiss === "cancel") {
          Swal.fire( "Cancelled", );
        }
      });
    }

    async function deleteQpRule (id) {
      Swal.fire(swalOpt).then(async function(result) {
        if (result.value) {
          await vendorStore.deleteVendorQpRule({VendorId: vendor.value.Id!, QpRuleId: id });
        } else if (result.dismiss === "cancel") {
          Swal.fire( "Cancelled")
        }
      });
    }

    return {
      vendor,
      qpRule,
      updateQp,
      submitButtonRef,
      handleDaysChange,
      addNewQpRule,
      submitQpRules,
      handleDiscountChange,
      templatesStore,
      validationSchema,
      submitAdditionQpRulesFromTemplate,
      deleteQpRule,
    };
  },
});
</script>