import {defineStore} from "pinia";
import {
    ApiClient, ApiError, CreateQpRuleTemplateCommand, DeleteQpRuleTemplateCommand,
    DiscountOfferStatus, QpRuleTemplateVM, UpdateQPRuleTemplateCommand
} from "../../../clients/ApiClient";
import {api} from "@/clients/ApiClientService";
import {QpRuleTemplatesVM} from "@/models/QpRuleTemplates/QpRuleTemplatesVM";
import router from "@/router";
import {errorToast, successToast} from "@/helpers/customToast";
import {useUserStore} from "@/store/currentUser/CurrentUserStore";

export const useQPTemplateStore = defineStore('QPTemplateStore',{
  state: ()  => {
    return {
      QPTemplatesState: Object as Partial<QpRuleTemplatesVM>,
      isEditQPTemplate: false,
      selectedQpRuleTemplateId: '',
    }
  },

  actions : {
    async getAllQPTemplates <QpRuleTemplatesVM>() {
        const res = await api.qpRuleTemplate.getAllTemplates().resolve();
        if (res.result !== undefined){
            this.$state.QPTemplatesState = res.result
        }else{
            errorToast();
        }
    },
      
    async createQPTemplate<bool> (model : QpRuleTemplateVM) {
        // const companyId = useUserStore().user.userState.companyId;
        const res = await api.qpRuleTemplate.createQpRuleTemplate({
            Name: model.Name,
            IsDefault:model.IsDefault,
            // CompanyId : companyId, 
            QpRules :model.QpRuleTemplateOptions 
        }).resolve();
        
        if (res.succeed){
            this.$state.isEditQPTemplate = false;
            this.$state.selectedQpRuleTemplateId = '';
            await this.getAllQPTemplates();
            successToast();
        }else{
            errorToast();
        }
        return res.succeed
    },
      
    async updateQPTemplate<bool> (model : QpRuleTemplateVM) {
        const res = await api.qpRuleTemplate.updateQpRuleTemplateOptions({
            TemplateQpOptionDtos : model.QpRuleTemplateOptions,
            Name : model.Name,
            QpRuleTemplateId: model.Id,
            IsDefault: model.IsDefault
        }).resolve();
        
        if (res.succeed){
            this.$state.isEditQPTemplate = false;
            this.$state.selectedQpRuleTemplateId = '';
            await this.getAllQPTemplates();
            successToast();
        } else {
            errorToast();
        }
        return res.succeed;
    },
      
    async deleteQPTemplate<bool> (command : DeleteQpRuleTemplateCommand) {
        const res = await api.qpRuleTemplate.deleteQpRuleTemplate(command).resolve();
        if (res.succeed){
            this.$state.isEditQPTemplate = false;
            this.$state.selectedQpRuleTemplateId = '';
            await this.getAllQPTemplates();
            successToast();
        }else{
            errorToast();
        }
        return res.succeed
    },
    
    async setUpdateData (id) {
        this.$state.selectedQpRuleTemplateId = id;
        this.$state.isEditQPTemplate = true;
    },
      
    async resetUpdateData () {
        this.$state.selectedQpRuleTemplateId = "";
        this.$state.isEditQPTemplate = false;
    },
  },
});
