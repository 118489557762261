/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceCreatedEvent } from '../models/InvoiceCreatedEvent';
import type { VendorEnabledToAutomaticallySendOfferEvent } from '../models/VendorEnabledToAutomaticallySendOfferEvent';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class IntegrationEventMethodsService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public invoiceCreatedForSending(
        requestBody?: InvoiceCreatedEvent,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationEventMethods/InvoiceCreatedEvent',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public vendorEnableForSendingOffer(
        requestBody?: VendorEnabledToAutomaticallySendOfferEvent,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/Api/V1/IntegrationEventMethods/VendorEnabledToAutomaticallySendOfferEvent',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
