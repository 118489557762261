<template>
  <div class="tab-pane fade" id="address" role="tabpanel">
    <div class="card mt-5 mb-xl-10">
      <div class="card-header">
        <div class="card-title">
          <h3>Address</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="row gx-9 gy-6">
          <div v-for="(item, index) in vendor.Addresses" :key="index" class="col-12 ">
            <div class="card card-dashed p-2 px-4">
              <div class="d-flex flex-column py-2">
                <div class="row">
                  <div class="col-2">
                    <label class=" fw-semibold text-muted">Type</label>
                    <div>
                              <span v-if="item.Type === AddressType.DELIVERY"
                                    class="badge badge-light-primary fs-7">{{ item.Type }}</span>
                      <span v-else class="badge badge-light-success fs-7">{{ item.Type }}</span>
                    </div>
                  </div>
                  <div class="col-2">
                    <label class="fw-semibold text-muted">Postal Code</label>
                    <div class="">
                      <span class="fw-bold fs-6 text-gray-800">{{ item.PostalCode }}</span>
                    </div>
                  </div>
                  <div class="col-1">
                    <label class="fw-semibold text-muted">Line 1</label>
                    <div class="">
                      <span class="fw-bold fs-6 text-gray-800">{{ item.Line1 }}</span>
                    </div>
                  </div>
                  <div class="col-3">
                    <label class="fw-semibold text-muted">Line 2</label>
                    <div class="">
                      <span class="fw-bold fs-6 text-gray-800">{{ item.Line2 }}</span>
                    </div>
                  </div>
                  <div class="col-4">
                    <label class=" fw-semibold text-muted">City</label>
                    <div class="">
                              <span class="fw-bold fs-6 text-gray-800">{{
                                  item.City
                                }}, {{ item.Region }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import {computed, defineComponent, PropType, ref} from "vue";
import {AddressDto, AddressType, VendorDto} from "../../../clients/ApiClient";
import {useVendorStore} from "@/store/vendor/VendorStore";

export default defineComponent({
  name: "vendor-address",
  components: {},
  // props: {
  //   vendorAddress: {
  //     type: Object as PropType<AddressViewModel[]>,
  //     required: true
  //   },
  // },
  setup() {
    const vendorStore = useVendorStore();
    const vendor = computed(() : VendorDto => vendorStore.vendorState);
    const address = ref<AddressDto[]>();
    address.value = vendor.value.Addresses?.map(x=>({Type: x.Type, Line1:x.Line1, Line2:x.Line2, City:x.City, Region:x.Region, Country:x.Country, PostalCode:x.PostalCode}));//.QpRules?.map(x => ({Id: x.Id, Days: x.Days, Discount: x.Discount, IsEdited: false}))
    return {
      vendor,
      address,
      AddressType
    };
  },
});
</script>