import {createApp} from "vue";
import Toast, {PluginOptions, useToast} from "vue-toastification";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import {initApexCharts} from "@/core/plugins/apexcharts";
import {initInlineSvg} from "@/core/plugins/inline-svg";
import {initVeeValidate} from "@/core/plugins/vee-validate";
import {createPinia} from 'pinia'
import "@/core/plugins/prismjs";

const app = createApp(App);
const pinia = createPinia();
app.use(Toast);

app.use(pinia);
app.use(store);
app.use(router);
app.use(ElementPlus);
ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

import {ApiError, CancelablePromise} from "../clients/ApiClient";
import {errorToast, successToast} from "@/helpers/customToast";

declare module "clients/ApiClient/core/CancelablePromise" {
    export interface CancelablePromise<T> {
        resolve(): Promise<{ succeed: boolean; result: T | undefined }>
    }
}

CancelablePromise.prototype.resolve = async function <T>(this: CancelablePromise<T>): Promise<{ succeed: boolean; result: T | undefined }> {
    try {
        const result = await this;
        return {
            succeed: true,
            result: result
        };
    } catch (e) {
        const error = e as ApiError;
        if (error.status === 500) {
            //await router.push({path: "/500"});
            return {
                succeed: false,
                result: undefined,
            };
        }
        //await router.push({path: "/404"});
        return {
            succeed: false,
            result: undefined,
        };
    }
};

app.mount("#app");
