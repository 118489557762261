<template>
    <div class="d-flex flex-column flex-xl-row">
        <div class="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
            <div class="card mb-5 mb-xl-8">
                <div class="card-body pt-15">
                    <div class="d-flex flex-center flex-column mb-5">
                        <a href="#" class="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"> {{ vendor.Name }} </a>
                        <div class="fs-5 fw-semobold text-muted mb-6">
                        </div>
                    </div>
                    <div class="d-flex flex-stack fs-4 py-3">
                        <div class="fw-bold rotate collapsible" data-bs-toggle="collapse"
                             href="#kt_customer_view_details" role="button" aria-expanded="false"
                             aria-controls="kt_customer_view_details">
                            Details
                            <span class="ms-2 rotate-180">
                                <span class="svg-icon svg-icon-3">
                                    <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
                                </span>
                            </span>
                        </div>

                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" title="Edit vendor details">
                            <button class="btn btn-sm btn-flex btn-light-primary" data-bs-toggle="modal"
                                    data-bs-target="#vendor-edit">
                                Edit
                            </button>
                        </span>
                    </div>
                    <div class="separator separator-dashed my-3"></div>
                    <div id="kt_customer_view_details" class="collapse show">
                        <div class="py-5 fs-6">
                            <div class="fw-bold mt-5">Contact Name</div>
                            <div class="text-gray-600">{{ vendor.ContactName }}</div>
                            <div class="fw-bold mt-5">Email</div>
                            <div class="text-gray-600">
                                <a href="#" class="text-gray-600 text-hover-primary">{{ vendor.Email }}</a>
                            </div>
                            <div class="fw-bold mt-5">Phone</div>
                            <div class="text-gray-600">
                                {{ vendor.Phone }}
                            </div>
                            <div class="fw-bold mt-5">Registration Number</div>
                            <div class="text-gray-600">{{ vendor.RegistrationNumber }}</div>
                            <div class="fw-bold mt-5">Tax Numbe</div>
                            <div class="text-gray-600">{{ vendor.TaxNumber }}</div>
                            <div class="fw-bold mt-5">Status</div>
                            <div class="text-gray-600">{{ vendor.Status }}</div>
                            <div class="fw-bold mt-5">External Modify Date</div>
                            <div class="text-gray-600">{{ formatExtDate(vendor.ExternalModifyDate) }}</div>
                            <div class="fw-bold mt-5">Send offer automatically</div>
                            <div class="form-check form-switch form-check-custom form-check-solid">
                                <div v-if="vendor.EnableAutomaticSending" class="badge badge-light-success">Yes <i class="bi bi-check-lg ms-2 fw-semobold c"></i></div><div v-else class="badge badge-light-danger ">No <i class="bi bi-x-lg ms-2"></i> </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-lg-row-fluid ms-lg-15">
            <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semobold mb-8">
                <li class="nav-item">
                    <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                       href="#kt_customer_view_overview_tab">Invoices</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                       href="#address">Address</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link text-active-primary pb-4" data-bs-toggle="tab"
                       href="#qp-rules">QP Rules</a>
                </li>
                <li class="nav-item ms-auto">
                    <a href="#" class="btn btn-primary ps-7"
                       data-kt-menu-trigger="click"
                       data-kt-menu-attach="parent"
                       data-kt-menu-placement="bottom-end">
                        Actions
                        <span class="svg-icon svg-icon-2 me-0">
                            <inline-svg src="/media/icons/duotune/arrows/arr072.svg" />
                        </span>
                    </a>
                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semobold py-4 w-250px fs-6"
                         data-kt-menu="true">
                        <div class="menu-item px-5">
                            <div class="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
                                Credentials
                            </div>
                        </div>
                        <div class="menu-item px-5">
                            <a @click="sendVendorCredentials" href="#" class="menu-link px-5">Send Credentials</a>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="kt_customer_view_overview_tab" role="tabpanel">
                    <InvoicesTable :vendor-id="vendor.Id" card-classes="mb-6 mb-xl-9"></InvoicesTable>
                </div>
                <Address />
                <QpRules />
            </div>
        </div>
    </div>
    <div class="modal fade" ref="newCardModalRef" id="vendor-edit" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header">
                    <h2>Vendor Details Edit</h2>
                    <div id="closeVendorEditModal" class="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">
                    <div class="d-flex flex-column mb-7">
                        <div class="row">
                            <div class="col-12 mb-4">
                                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                                    <span class="required">Name</span>
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                       title="Name"></i>
                                </label>
                                <input v-bind:value="vendor?.Name" @input="vendor.Name = $event.target.value"
                                       class="form-control form-control-solid" />
                            </div>
                            <div class="col-6 mb-4">
                                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                                    <span class="required">Contact Name</span>
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                       title="Contact name"></i>
                                </label>
                                <input v-bind:value="vendor?.ContactName" @input="vendor.ContactName = $event.target.value"
                                       class="form-control form-control-solid" />
                            </div>
                            <div class="col-6 mb-4">
                                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                                    <span class="required">Registration Number</span>
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                       title="Registration Number"></i>
                                </label>
                                <input v-bind:value="vendor?.RegistrationNumber"
                                       @input="vendor.RegistrationNumber = $event.target.value"
                                       class="form-control form-control-solid" />
                            </div>
                            <div class="col-6 mb-4">
                                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                                    <span class="required">Email</span>
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                       title="Email"></i>
                                </label>
                                <input v-bind:value="vendor?.Email" @input="vendor.Email = $event.target.value"
                                       class="form-control form-control-solid" />
                            </div>
                            <div class="col-6 mb-4">
                                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                                    <span class="required">Phone</span>
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                       title="Phone"></i>
                                </label>
                                <input v-bind:value="vendor?.Phone" @input="vendor.Phone = $event.target.value"
                                       class="form-control form-control-solid" />
                            </div>
                           
                            <div class="col-6 mb-4">
                                <label class="d-flex align-items-center fs-6 fw-semobold form-label mb-2">
                                    <span class="required">Enable Send offer automatically</span>
                                    <i class="fas fa-exclamation-circle ms-2 fs-7" data-bs-toggle="tooltip"
                                       title="Enable Send offer automatically"></i>
                                </label>
                                <div class="mb-10">
                                    <div class="form-check form-switch form-check-custom form-check-solid">
                                        <div class="form-check form-switch form-check-custom form-check-solid">
                                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckedEdit" v-model="vendor.EnableAutomaticSending">
                                            <label class="form-check-label" :for="flexSwitchCheckedEdit">{{ vendor.EnableAutomaticSending ? 'Enabled' : 'Disabled' }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="text-center pt-15">
                        <button @click="submitVendorDetailsEdit" type="button" class="btn btn-primary">
                            <span class="indicator-label"> Save </span>
                            <span class="indicator-progress">
                                Please wait...
                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { computed, defineComponent, onMounted, ref } from "vue";
    import { UpdateVendorCommand, VendorDto } from "../../../clients/ApiClient";
    import { api } from "@/clients/ApiClientService";
    import { dateFormat } from "@/extensions/dateExtension";
    import InvoicesTable from "@/components/venodr/InvoicesTable.vue";
    import QpRules from "@/components/venodr/QpRules.vue";
    import Address from "@/components/venodr/Address.vue";
    import Swal from "sweetalert2/dist/sweetalert2.js"
    import { useVendorStore } from "@/store/vendor/VendorStore";
    import { PluginOptions, POSITION, TYPE, useToast } from "vue-toastification";
    import { useUserStore } from "@/store/currentUser/CurrentUserStore";
    import router from "@/router";

    export default defineComponent({
        name: "VendorDetails",
        components: {
            Address,
            InvoicesTable,
            QpRules
        },

        props: ['id'],
        setup(props) {
            const userStore = useUserStore();
            if (!userStore.userState.isCompany && !userStore.userState.isInvitedUser) {
                router.push("/404");
            }

            const vendorStore = useVendorStore();
            const vendor = computed((): VendorDto => vendorStore.vendorState);
            const isMounted = ref<boolean>(false);
            onMounted(async () => {
                await vendorStore.getVendor(props.id);
                isMounted.value = true;
            });

            function sendVendorCredentials() {
               Swal.fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    reverseButtons: true
                }).then(async function (result) {
                    if (result.value) {
                      await vendorStore.sendCredentials(props.id)
                    } else if (result.dismiss === "cancel") {
                       Swal.fire(
                            "Cancelled",
                        );
                    }
                });
            }
            const formatExtDate = (date) => {
                // Check if the date is a default date value
                if (new Date(date) < new Date('1980-01-01T00:00:00Z') || !date) {
                    return '';
                }
                return dateFormat(date);
            };
            async function submitVendorDetailsEdit() {
                Swal.fire({
                    title: "Are you sure?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Yes",
                    cancelButtonText: "No",
                    reverseButtons: true
                }).then(async function (result) {
                    if (result.value) {

                        const elem = document.getElementById('closeVendorEditModal');
                        elem?.click();
                        await vendorStore.editVendorDetails({
                            Id: vendor.value.Id,
                            Name: vendor.value.Name,
                            RegistrationNumber: vendor.value.RegistrationNumber,
                            Email: vendor.value.Email,
                            Phone: vendor.value.Phone,
                            ContactName: vendor.value.ContactName,
                            OfferExpirationDays: vendor.value.OfferExpirationDays!,
                            EnableAutomaticSending: vendor.value.EnableAutomaticSending,
                        } as UpdateVendorCommand)
                    } else if (result.dismiss === "cancel") {
                        Swal.fire(
                            "Cancelled",
                        );
                    }
                });
            }

            return {
                vendor,
                formatExtDate,
                formatDate: dateFormat,
                sendVendorCredentials,
                submitVendorDetailsEdit,
                isMounted
            }
        },
    });
</script>