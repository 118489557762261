import {createRouter, createWebHistory, RouteRecordRaw} from "vue-router";
import store from "@/store";
import {Mutations} from "@/store/enums/StoreEnums";
import InvoicesDetails from "@/views/invoices/InvoicesDetails.vue";
import CompanyDetails from "@/views/companies/CompanyDetails.vue";
import VendorInvoiceDetails from "@/views/VendorDashboard/VendorInvoiceDetails.vue";
import VendorsDetails from "@/views/Vendors/VendorsDetails.vue";
import {useUserStore} from "@/store/currentUser/CurrentUserStore";
import {api} from "@/clients/ApiClientService";
import {CompanyAccountingStatus, CompanyAccountingStatusDetails} from "../../clients/ApiClient";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        component: () => import("@/layout/Layout.vue"),
        children: [
            {
                path: "/vendors",
                name: "vendors",
                component: () => import("@/views/Vendors.vue"),
            },
            {
                path: "/QPRuleTemplates",
                name: "layout-QPRuleTemplates",
                component: () => import("@/views/QPRuleTemplates.vue"),
            },
            
            {
                path: "/companies/AccountConnections",
                name: "company-account-connections",
                component: () => import("@/views/companies/AccountConnections.vue"),
            },
            {
                path: "/companies/UserInvitations",
                name: "company-user-invitations",
                component: () => import("@/views/companies/UserInvitations.vue"),
            },

            {
                path: "/VendorDashboard",
                name: "VendorDashboard",
                component: () => import("@/views/VendorDashboard.vue"),
            },
            {
                path: "/CompanyDashboard",
                name: "CompanyDashboard",
                component: () => import("@/views/CompanyDashboard.vue"),
            },
            {
                path: "/invoices",
                name: "invoices",
                component: () => import("@/views/Invoices.vue"),
            },
            {
                path: "/invoices/:id",
                name: "InvoicesDetails",
                component: InvoicesDetails,
                props: true,
            },
            {
                path: "/VendorDashboard/:id/:forViewOffer?",
                name: "VendorInvoicesDetails",
                component: VendorInvoiceDetails,
                props: true,
            },
            {
                path: "/Vendors/:id",
                name: "VendorsDetails",
                component: VendorsDetails,
                props: true,
            },
        ],
    },

    {
        path: "/",
        component: () => import("@/components/page-layouts/Auth.vue"),
        children: [
            {
                path: "/sign-in",
                name: "sign-in",
                component: () =>
                    import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
            },
            {
                path: "/sign-up",
                name: "sign-up",
                component: () =>
                    import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
            },
            {
                path: "/password-reset",
                name: "password-reset",
                component: () =>
                    import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
            },
        ],
    },
    {
        path: "/multi-step-sign-up",
        name: "multi-step-sign-up",
        component: () =>
            import("@/views/crafted/authentication/MultiStepSignUp.vue"),
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
    },
    {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404",
    },
    {
        path: "/onboarding",
        name: "onboarding",
        component: () => import("@/views/Onboarding.vue"),
    },
    {
        path: "/EmailNotVerified",
        name: "EmailNotVerified",
        component: () => import("@/views/EmailNotVerified.vue"),
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach(async (to, from) => {
    const userStore = useUserStore();
    const userUrl = "/bff/user";
    const req = new Request(userUrl, {
        headers: new Headers({
            'X-CSRF': '1'
        })
    });
    if (to.path === "/EmailNotVerified") return;
    
    try {
        const resp = await fetch(req);
        if (resp.ok) {
            const claims: { type: string; value: string }[] = await resp.json();
            const user_nickname = claims.find(c => c.type === "nickname");
            const user_name = claims.find(c => c.type === "name");
            const email = claims.find(c => c.type === "email")!;
            const logoutUrl = claims.find(c => c.type === "bff:logout_url")!;
            const isVendor = claims.find(c => c.type === "role" && c.value === "vendor")?.value;
            const isCompany = claims.find(c => c.type === "role" && c.value === "company_user")?.value;
            const isInvitedUser = claims.find(c => c.type === "role" && c.value === "invited_user_role")?.value;
            
            userStore.setUserInfo({
                ...userStore.userState,
                userId: email.value,
                nickname: user_nickname?.value,
                name: user_name?.value,
                logoutUrl: logoutUrl.value,
                isVendor : isVendor !== undefined,
                isCompany : isCompany !== undefined,
                isInvitedUser : isInvitedUser !== undefined,
            });
        } else if (resp.status === 401) {
            location.href = "/bff/login?returnUrl="
        }

    } catch (e) {
        console.log("error checking user status");
    }

    store.commit(Mutations.RESET_LAYOUT_CONFIG);

    if (to.path === "/onboarding" || to.path === "/CompanyDashboard") return;
    
    if (localStorage.getItem("vendorStatus")) {
        const persistedStatus = JSON.parse(localStorage.getItem("vendorStatus")!);
        userStore.setUserInfo({
            ...userStore.userState,
            vendorId: persistedStatus.vendorId ?? undefined
        });
        return;
    }
    
    if (localStorage.getItem("companyStatus")) {
        const persistedStatus = JSON.parse(localStorage.getItem("companyStatus")!) as CompanyAccountingStatusDetails;
        userStore.setUserInfo({
            ...userStore.userState,
            company: persistedStatus.Details,
            companyId: persistedStatus.CompanyId
        });

       
        // redirect to /CompanyDashboard if it's the default URL
        const isGetRequest = req.method === 'GET';
        const isDefaultUrl = window.location.pathname === '/';
        if (isGetRequest && isDefaultUrl && to.path !== '/CompanyDashboard') {
            router.push({ path: '/CompanyDashboard' });
            return;
        }
        return;
    }
    

    const status = await api.company.getStatus();
   
    if (status.Status === CompanyAccountingStatus.IS_VENDOR) {
        localStorage.setItem("vendorStatus", JSON.stringify({
            vendorId: status.VendorId
        }));
        router.push({path: "/VendorDashboard"});
        return;
    }
    
    // if (userStore.userState.isInvitedUser) {
    //     router.push({ path: '/CompanyDashboard' });
    //     return;
    // }
    
    if (status.Status === CompanyAccountingStatus.NO_COMPANY) {
        router.push({path: "/onboarding"});
        return;
    }


    //TODO: REMOVE!
    if (status.CompanyId) {
        localStorage.setItem("company_ID", status.CompanyId);
    }
    //if ((status.Status === CompanyAccountingStatus.ACCOUNTING_NOT_LINKED
    //    || status.Status === CompanyAccountingStatus.ACCOUNTING_NO_CONNECTIONS) && from.path !== "/companies/AccountConnections") {
    //    //location.href = status.RedirectUrl!;
    //    router.push({path: "/companies/AccountConnections"});
    //    return;
    //}
    if (to.path==='/') {
        router.push({ path: '/CompanyDashboard' });
        return;
    }
    if (status.Status === CompanyAccountingStatus.ACCOUNTING_CONNECTED) {
        localStorage.setItem("companyStatus", JSON.stringify(status))
    }

    //store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });



    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
});

export default router;

